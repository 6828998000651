
import moment from "moment";
import { Link } from "react-router-dom";

const getRowNumber = (currentPage, index) => {
    return (currentPage - 1) * 10 + index + 1;
};

const support = (currentPage) => [
    {
        title: 'No.',
        dataIndex: 'no',
        render: (text, record, index) => getRowNumber(currentPage, index),
    },
    {
        title: 'Priority',
        dataIndex: 'priority',
        key: 'priority',
    },
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text) => (
            <div className={`${text === 'open' ? 'text-[#4BD890]' : 'text-[#E46253]'} `}>
                <span className="me-1">⦿</span>{text.toUpperCase()}
            </div>
        )
    },
    {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        render: (text, record) => (
            <Link to={`/support/${record?.id}`}><img src='/Assets/eye_icon.png' alt="View" className="mx-auto" /></Link>
        )
    },
];
export default support;

