import React, { useEffect, useState } from 'react';
import { DatePicker, Pagination, Table } from 'antd';
import ApiClient from '../Services/ApiServices/ApiClinet.jsx';
import { toast } from 'react-toastify';
import loader from '../Services/Loader/Loader.jsx';
import Navbar from '../Components/Navbar.jsx';
import kycReport_columns from '../Columns/ReportManager/kycReport.js';
import { CSVLink } from 'react-csv';
import BackButton from '../Components/BackButton.jsx';


export default function KycReport() {

    const [kycData, setKycData] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [submit, setSubmit] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [csvData, setCsvData] = useState([]);
    const [isExport, setIsExport] = useState(false);

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleSubmit = () => {
        setSubmit(true);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    const getKycReport = (page) => {
        loader(true);
        ApiClient.post('kycreport', { start_date: startDate, end_date: endDate, is_export: 0, page })
            .then((response) => {
                if (response?.status) {
                    setKycData(response?.data);
                    setTotalPages(response?.total_record);
                } else {
                    toast.error(response?.message);
                }
                loader(false);
            })
            .catch(error => {
                toast.error(error.message);
                loader(false);
            });
    };
    const handleExport = () => {
        setIsExport(true);
        loader(true);
        ApiClient.post('kycreport', { start_date: startDate, end_date: endDate, is_export: 1 })
            .then((response) => {
                if (response?.status) {
                    setCsvData(response?.data);
                    toast.success('Export data successfully..');
                } else {
                    toast.error(response?.message);
                }
                loader(false);
                setIsExport(false);
            })
            .catch(error => {
                toast.error(error.message);
                loader(false);
                setIsExport(false);
            });
    };

    useEffect(() => {
        if (csvData.length > 0) {
            document.getElementById('csv-download-link').click();
        }
    }, [csvData]);
    
    useEffect(() => {
        document.title = 'Apex Bullion-KYC Report'
        if (submit && startDate && endDate) {
            getKycReport(currentPage);
            setSubmit(false);
        }
    }, [submit, startDate, endDate, currentPage]);

    return (
        <>
            <Navbar currentPage='KYC Report' />
            <div className='flex  sm:flex-row flex-col justify-between sm:items-end gap-2 mt-5'>
                <div className='flex gap-3'>
                    <BackButton />
                    {kycData.length > 0 && (
                        <>
                            <button
                                className='btnGradient py-1 px-3 rounded-md hover:scale-105 duration-300 flex gap-2'
                                onClick={handleExport}
                                disabled={isExport}
                            >
                                <img src="/Assets/Export.svg" alt="" />
                                EXPORT
                            </button>
                            <CSVLink
                                data={csvData}
                                filename={'kyc_report.csv'}
                                id='csv-download-link'
                                className='hidden'
                            />
                        </>
                    )}
                </div>
                <div className='flex  gap-5'>
                    <div className='flex flex-col'>
                        <label htmlFor="sdate">Start Date</label>
                        <DatePicker format={'DD-MM-YYYY'} onChange={handleStartDateChange} className='py-1'
                            disabledDate={current => { return current && (current > new Date() || current > new Date(endDate)); }}
                        />
                    </div>
                    <div className='flex flex-col'>
                        <label htmlFor="edate">End Date</label>
                        <DatePicker format={'DD-MM-YYYY'} onChange={handleEndDateChange} className='py-1'
                            disabledDate={current => { return current && (current > new Date() || current < new Date(startDate)); }}
                        />
                    </div>
                    <div className='mt-auto'>
                        <button onClick={handleSubmit}
                            className='btnGradient py-1 px-3 rounded-md hover:scale-105 duration-300'>
                            Submit
                        </button>
                    </div>
                </div>
            </div>

            <div className="relative overflow-x-auto shadow-md darkBg rounded-lg border-2 borderStyle mt-4">
                <Table dataSource={kycData} columns={kycReport_columns(currentPage)} pagination={false} />
            </div>
            <div className='text-right mt-5'>
                <Pagination current={currentPage} total={totalPages} onChange={handlePageChange} />
            </div>
        </>
    );
}
