import React, { useEffect, useState } from 'react';
import Navbar from '../Components/Navbar';
import ApiClient from '../Services/ApiServices/ApiClinet';
import loader from '../Services/Loader/Loader';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import BarChart from '../Components/BarChart';

export default function Home() {

  const [data, setData] = useState([]);
  const [depositeCount, setDepositeCount] = useState([]);
  const [withdrawCount, setWithdrawCount] = useState([]);

  const navigate = useNavigate();

  const getDashboardData = () => {
    loader(true);
    ApiClient.post('merchantdashboard')
      .then((response) => {
        if (response?.message === "Invalid Token!") {
          localStorage.clear();
        } else {
          if (response?.status) {
            setData(response?.data);
          } else {
            toast.error(response?.message);
          }
        }
        loader(false);
      })
      .catch(error => {
        toast.error(error.message);
        loader(false);
      })
  }

  const getChartData = () => {
    loader(true);
    ApiClient.post('merchantdashboardchart')
      .then((response) => {
        if (response.status) {
          setDepositeCount(response.sell_count);
          setWithdrawCount(response.buy_count);
        }
        else {
          toast.error(response.message)
        }
        loader(false);
      })
      .catch(error => {
        toast.error(error.message);
        loader(false);
      })
  }

  useEffect(() => {
    getDashboardData();
    getChartData();
    document.title = 'Apex Bullion-Home';
  }, []);

  return (
    <div>
      <Navbar currentPage='Home' />
      {/* <div className='bgImageHome flex md:flex-row flex-col justify-between md:items-center px-20 py-3 rounded-3xl mt-4'>
        <div className='flex md:flex-row flex-col md:items-start items-center '>
          <div >
            <h3 className='font-semibold text-xl'> Hello, Good Morning! </h3>
            <p className='font-normal text-sm tracking-wide pt-2 leading-6'>You have some very important Message and Notifications
              <br />from the Apex Bullion. Please check it out Here.
            </p>
          </div>
        </div>
        <div>
          <img src="/Assets/image8.png" alt="" className='max-w-full max-h-full p-5' />
        </div>
      </div> */}

      <div className='grid lg:grid-cols-3 md:grid-cols-2 gap-4 text-lg mt-4 '>
        <div className='darkBg border-2 rounded-xl borderStyle duration-300 hover:scale-105  cursor-pointer flex justify-between p-4'
          onClick={() => navigate('/goldportfolio')}>
          <div>
            <p>Gold Stock</p>
            <p className='lightYellowText mt-1'>{Number(data.total_gold_stock).toFixed(4)}</p>
            <p className='mt-1'>$ {data.total_gold_avg}/G Avg.</p>
          </div>
          <div><img src="/Assets/Menu.svg" alt="" /></div>
        </div>
        <div className='darkBg border-2 rounded-xl borderStyle duration-300 hover:scale-105 cursor-pointer flex justify-between p-4'
          onClick={() => navigate('/goldportfolio')}>
          <div>
            <p>Client Stock</p>
            <p className='lightYellowText mt-1'>{Number(data.client_stock).toFixed(4)}</p>
            <p className='mt-1'>$ {data.client_stock_avg}/G Avg.</p>
          </div>
          <div><img src="/Assets/Menu.svg" alt="" /></div>
        </div>
        <div className='darkBg border-2 rounded-xl borderStyle duration-300 hover:scale-105  cursor-pointer flex justify-between p-4'
          onClick={() => navigate('/clientmanager')}>
          <div>
            <p>{data.total_client}  Client</p>
            <p className='lightYellowText mt-1'>{data.total_active_client} - Active Client</p>
            <p className='lightYellowText mt-1'>{data.total_inactive_client} - InActive Client</p>
          </div>
          <div><img src="/Assets/Menu.svg" alt="" /></div>
        </div>
        <div className='darkBg border-2 rounded-xl borderStyle duration-300 hover:scale-105  cursor-pointer p-4' onClick={() => navigate('/depositmanager')}>
          <div className='flex justify-between'>
            <p >Deposite </p>
            <div><img src="/Assets/Menu.svg" alt="" /></div>
          </div>
          <div className='flex justify-between lightYellowText mt-3'>
            <div>
              <p className='text-center'>{data.deposite_completed}</p>
              <p>Complete</p>
            </div>
            <div>
              <p className='text-center'>{data.deposite_pending}</p>
              <p>Pendig</p>
            </div>
            <div>
              <p className='text-center'>{data.deposite_reject}</p>
              <p>Reject</p>
            </div>
          </div>
        </div>
        <div className='darkBg border-2 rounded-xl borderStyle duration-300 hover:scale-105  cursor-pointer p-4' onClick={() => navigate('/withdrawmanager')}>
          <div className='flex justify-between'>
            <p >Withdraw</p>
            <div><img src="/Assets/Menu.svg" alt="" /></div>
          </div>
          <div className='flex justify-between lightYellowText mt-3'>
            <div>
              <p className='text-center'>{data.withdraw_completed}</p>
              <p>Completed</p>
            </div>
            <div>
              <p className='text-center'>{data.withdraw_pending}</p>
              <p>Pendig</p>
            </div>
            <div>
              <p className='text-center'>{data.withdraw_reject}</p>
              <p>Reject</p>
            </div>
          </div>
        </div>
        <div className='darkBg border-2 rounded-xl borderStyle duration-300 hover:scale-105  cursor-pointer p-4' onClick={() => navigate('/deliverymanager')}>
          <div className='flex justify-between'>
            <p >Delivery</p>
            <div><img src="/Assets/Menu.svg" alt="" /></div>
          </div>
          <div className='flex justify-between lightYellowText mt-3'>
            <div>
              <p className='text-center'>{data.delivery_completed}</p>
              <p>Completed</p>
            </div>
            <div>
              <p className='text-center'>{data.delivery_pending}</p>
              <p>Pendig</p>
            </div>
            <div>
              <p className='text-center'>{data.delivery_reject}</p>
              <p>Reject</p>
            </div>
          </div>
        </div>
      </div>
      <BarChart deposite={depositeCount} withdraw={withdrawCount} />
    </div>
  )
}
