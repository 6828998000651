import { Image } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";

const getRowNumber = (currentPage, index) => {
    return (currentPage - 1) * 10 + index + 1;
};

const kycReport_columns = (currentPage) => [
    {
        title: 'No.',
        dataIndex: 'no',
        render: (text, record, index) => getRowNumber(currentPage, index),
    },
    {
        title: 'Name',
        dataIndex: 'client',
        key: 'name',
        sorter: (a, b) => a.client?.first_name?.localeCompare(b.client?.first_name),
        render: (text, record) => (<>{text?.first_name} {text?.last_name}</>)
    },
    {
        title: 'Email',
        dataIndex: 'client',
        key: 'email',
        sorter: (a, b) => a.client?.email?.localeCompare(b.client?.email),
        render: (record) => record?.email,
    },
    {
        title: 'Mobile',
        dataIndex: 'client',
        key: 'mobile_no',
        sorter: (a, b) => a.client?.mobile_no?.localeCompare(b.client?.mobile_no),
        render: (record) => record?.mobile_no,
    },
    {
        title: 'Document Type',
        dataIndex: 'document_type',
        key: 'document_type',
        sorter: (a, b) => a.document_type?.localeCompare(b.document_type),
    },
    {
        title: 'Document Number',
        dataIndex: 'document_number',
        key: 'document_number',
        sorter: (a, b) => a.document_number?.localeCompare(b.document_number),
    },
    {
        title: 'Document Image',
        dataIndex: 'document_img',
        key: 'document_img',
        render: (text, record) => (
            <Image src={text} alt="Document" style={{ borderRadius: '50%', width: '30px', height: '30px' }} />
        ),
    },
   
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        sorter: (a, b) => a.status?.localeCompare(b.status),
        render: (text) => {
            let color = '';
            let backgroundColor = '';
            let upperCaseText = text.toUpperCase();

            if (text === 'pending' || text === 'Pending') {
                color = '#F0A11B';
                backgroundColor = '#f0a11b3b';
            } else if (text === 'completed' || text === 'Completed') {
                color = '#009900';
                backgroundColor = '#00800040';
            } else if (text === 'reject' || text === 'Reject') {
                color = '#E20001';
                backgroundColor = '#e2000140';
            }
            return (
                <div style={{ color, backgroundColor, borderRadius: '4px' }}>
                    {upperCaseText}
                </div>
            );
        }
    },
];

export default kycReport_columns;