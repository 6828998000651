import React, { useEffect } from 'react';
import Navbar from '../Components/Navbar';
import { Link } from 'react-router-dom';
export default function ReportManager() {
    useEffect(() => { document.title = 'Apex Bullion-Report Manager' }, []);
    return (
        <>
            <Navbar currentPage={'Report Manager'} />
            <div className='grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-5 mt-5'>
                <Link to={'/kycreport'}>
                    <div
                        className='bgGradient flex flex-col py-5 gap-5 items-center justify-center  border-2 rounded-xl borderStyle duration-300 hover:scale-105 cursor-pointer'>
                        <img src="/Assets/KycReport.svg" alt="" />
                        <p>KYC Report</p>
                    </div>
                </Link>
                <Link to={'/clientreport'}>
                    <div
                        className='bgGradient flex flex-col py-5 gap-5 items-center justify-center  border-2 rounded-xl borderStyle duration-300 hover:scale-105 cursor-pointer'>
                        <img src="/Assets/ClientReport.svg" alt="" />
                        <p>Client Report</p>
                    </div>
                </Link>
                <Link to={'/deliveryreport'}>
                    <div
                        className='bgGradient flex flex-col py-5 gap-5 items-center justify-center  border-2 rounded-xl borderStyle duration-300 hover:scale-105 cursor-pointer'>
                        <img src="/Assets/deliveryReport.svg" alt="" />
                        <p>Delivery Report</p>
                    </div>
                </Link>
                <Link to={'/depositreport'}>
                    <div
                        className='bgGradient flex flex-col py-5 gap-5 items-center justify-center  border-2 rounded-xl borderStyle duration-300 hover:scale-105 cursor-pointer'>
                        <img src="/Assets/DepositReport.svg" alt="" />
                        <p>Deposit Report</p>
                    </div>
                </Link>
                <Link to={'/withdrawreport'}>
                    <div
                        className='bgGradient flex flex-col py-5 gap-5 items-center justify-center  border-2 rounded-xl borderStyle duration-300 hover:scale-105 cursor-pointer'>
                        <img src="/Assets/WithdrawReport.svg" alt="" />
                        <p>Withdraw Report</p>
                    </div>
                </Link>
            </div>
        </>
    )
}
