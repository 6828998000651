import React, { useEffect, useState } from 'react';
import Navbar from '../Components/Navbar';
import { Pagination, Table } from 'antd';
import ApiClient from '../Services/ApiServices/ApiClinet';
import { toast } from 'react-toastify';
import loader from '../Services/Loader/Loader';
import goldPortfolio_columns from '../Columns/GoldPortfolio/goldPortfolio';

export default function GoldPortfolio() {

  const [goldPortfolioData, setGoldPortFolioData] = useState([]);
  const [buySellData, setBuySellData] = useState([]);
  const [totalStock, setTotalStock] = useState('');
  const [clientStock, setClientStock] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);


  const getGoldPortfolio = (page) => {
    loader(true);
    ApiClient.post('goldportfolio', { page })
      .then((response) => {
        if (response.status) {
          setTotalStock(response?.total_stock);
          setClientStock(response?.client_stock);
          // setGoldPortFolioData(response?.data);          
        }
        else {
          toast.error(response.message);
        }
        loader(false);
      })
      .catch(error => {
        toast.error(error.message);
        loader(false);
      })
  }

  const getGoldBuySell = (page) => {
    loader(true);
    ApiClient.post('goldbuyselllist', page)
    .then((response)=>{
      if(response.status){
        setBuySellData(response?.data);
        setTotalPages(response?.total_record);
      }
      else{
        toast.error(response.message);
      }
      loader(false);
    })
    .catch(error=>{
      toast.error(error.message);
      loader(false);
    })
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  }

  useEffect(() => {
    getGoldPortfolio(currentPage);
    getGoldBuySell(currentPage);
    document.title = 'Apex Bullion-Gold Portfolio'
  }, [currentPage]);

  return (
    <>
      <Navbar currentPage='Gold Portfolio' />
      <div className='grid grid-cols-2 gap-3 mt-3'>
        <div className='darkBg border-2 rounded-xl borderStyle flex justify-between p-4'>
          <div>
            <p>Client Stock</p>
            <p className='lightYellowText mt-1'>{Number(clientStock).toFixed(4)}</p>
            <p className='mt-1'></p>
          </div>
          <div><img src="/Assets/dashBoardIcon.png" alt="" /></div>
        </div>
        <div className='darkBg border-2 rounded-xl borderStyle flex justify-between p-4'>
          <div>
            <p>Total Stock</p>
            <p className='lightYellowText mt-1'>{Number(totalStock).toFixed(4)}</p>
            <p className='mt-1'></p>
          </div>
          <div><img src="/Assets/dashBoardIcon.png" alt="" /></div>
        </div>
      </div>
      <div className="relative overflow-x-auto shadow-md darkBg rounded-lg border-2 borderStyle mt-3">
        <Table dataSource={buySellData} columns={goldPortfolio_columns(currentPage)} pagination={false} />
      </div>
      <div className='text-right mt-5'>
        <Pagination current={currentPage} total={totalPages} onChange={handlePageChange} />
      </div>
    </>
  )
}
