import React, { useEffect, useState } from 'react';
import Navbar from '../Components/Navbar';
import { Pagination, Radio, Table } from 'antd';
import loader from '../Services/Loader/Loader';
import { toast } from 'react-toastify';
import ApiClient from '../Services/ApiServices/ApiClinet';
import delivery_columns from '../Columns/DeliveryManager/delivery';

export default function DeliveryManage() {
  const [deliveryData, setDeliveryData] = useState();
  const [statusFilter, setStatusFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const getDeliveryData = (page) => {
    loader(true);
    ApiClient.post('deliverylist', { status: statusFilter, page })
      .then((response) => {
        if (response?.status) {
          setDeliveryData(response?.data);
          setTotalPages(response?.total_record);
        }
        else {
          toast.error(response?.message);
        }
        loader(false);
      })
      .catch(error => {
        toast.error(error.message);
        loader(false);
      })
  }
  useEffect(() => {
    getDeliveryData(currentPage);
    document.title = 'Apex Bullion-Delivery Manager'
  }, [statusFilter, currentPage]);

  useEffect(() => {
    setCurrentPage(1)
  }, [statusFilter]);


  return (
    <>
      <Navbar currentPage='Delivery Manage' />
      <div className='flex sm:flex-row flex-col gap-2 justify-end mt-3'>
        <Radio.Group value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)} buttonStyle="solid" className='flex'>
          <Radio.Button className='text-nowrap text-center' value="">All</Radio.Button>
          <Radio.Button className='text-nowrap text-center' value="pending">Pending</Radio.Button>
          <Radio.Button className='text-nowrap text-center' value="completed">Completed</Radio.Button>
          <Radio.Button className='text-nowrap text-center' value="reject">Reject</Radio.Button>
        </Radio.Group>
      </div>
      <div className="relative overflow-x-auto shadow-md darkBg rounded-lg border-2 borderStyle mt-3">
        <Table dataSource={deliveryData} columns={delivery_columns(currentPage)} pagination={false} />
      </div>
      <div className='text-right mt-5'>
        <Pagination current={currentPage} total={totalPages} onChange={page => setCurrentPage(page)} />
      </div>
    </>
  )
}
