import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import ApiClient from '../Services/ApiServices/ApiClinet';
import { toast } from 'react-toastify';
import loader from '../Services/Loader/Loader.jsx';
import { useNavigate } from 'react-router-dom';
import { Input, Select } from 'antd';

const SignUp = () => {
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [countryId, setCountryId] = useState("");
    const [stateId, setStateId] = useState("");
    const [cityId, setCityId] = useState("");

    const initialValues = {
        name: '',
        email: '',
        password: '',
        mobile_no: '',
        shop_name: '',
        shop_address: '',
        country: '',
        country_code: '',
        state: '',
        city: ''
    };

    const navigate = useNavigate();

    const userRegisterFormSchema = Yup.object().shape({
        name: Yup.string()
            .trim()
            .matches(/^[a-zA-Z\s]+$/, 'Name should contain only alphabetic')
            .required("Please enter your name"),
        email: Yup.string()
            .trim()
            .email("Please enter valid email address.")
            .required("Please enter your email address.")
            .test('is-valid-email', 'Please enter valid email address.',
                value => value && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
            ),
        password: Yup.string()
            .trim()
            .min(8, "Password must be at least 8 characters")
            .matches(/[a-z]/, "Enter at least one lowercase letter")
            .matches(/[A-Z]/, "Enter at least one uppercase letter")
            .matches(/[0-9]/, "Enter at least one number")
            .matches(/[@$!%*?&#]/, "Enter at least one special character")
            .required("Please enter your password"),
        mobile_no: Yup.string()
            .matches(/^\d+$/, 'Mobile no. contain only numbers')
            .matches(/^\d{7,15}$/, 'Mobile no. between 7 to 15 digits')
            .trim()
            .required("Please enter your mobile number"),
        shop_name: Yup.string()
            .trim()
            .required("Please enter your shop name"),
        shop_address: Yup.string()
            .trim()
            .required("please enter your shop address"),
    });

    const handleSubmit = (values, { resetForm }) => {
        const selectedCountry = countryList?.find(country => country.id === countryId);
        const selectedState = stateList?.find(state => state.id === stateId);
        const selectedCity = cityList?.find(city => city.id === cityId);
        const params = {
            ...values,
            country: selectedCountry?.name || '',
            country_code: selectedCountry?.phonecode || '',
            state: selectedState?.name || '',
            city: selectedCity?.name || ''
        };
        ApiClient.post('merchantregister', params)
            .then((response) => {
                if (response?.status) {
                    toast.success(response?.message);
                    resetForm();
                    navigate('/login');
                } else {
                    toast.error(response?.message);
                }
            })
            .catch((error) => {
                toast.error(error.message);
                loader(false);
            });
    };

    const getCountryList = () => {
        loader(true);
        ApiClient.post('countrystatecity')
            .then((response) => {
                if (response) {
                    setCountryList(response);
                    setCountryId(response[0].id)
                }
                loader(false);
            })
            .catch(err => {
                toast.error(err.message);
                loader(false);
            });
    };

    const getStateList = (countryID) => {
        loader(true);
        ApiClient.post('countrystatecity', { country_id: countryID })
            .then((response) => {
                if (response) {
                    setStateList(response);
                    setStateId(response[0]?.id)
                }
                loader(false);
            })
            .catch(err => {
                toast.error(err.message);
                loader(false);
            });
    };
    const getCityList = (stateID) => {
        loader(true);
        ApiClient.post('countrystatecity', { state_id: stateID })
            .then((response) => {
                if (response) {
                    setCityList(response);
                    setCityId(response[0]?.id)
                }
                loader(false);
            })
            .catch(err => {
                toast.error(err.message);
                loader(false);
            });
    };

    const handleCountry = (value) => {
        setCountryId(value);
        getStateList(value);
    };

    const handleState = (value) => {
        setStateId(value);
        getCityList(value);
    };

    useEffect(() => {
        getCountryList();
        document.title = 'Apex Bullion-SignUp';
    }, []);

    useEffect(() => {
        if (countryId) {
            getStateList(countryId);
        }
    }, [countryId])

    useEffect(() => {
        if (stateId) {
            getCityList(stateId)
        }
    }, [stateId])



    return (
        <div className="mx-auto h-[100vh]">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 h-full">
                <div className='bgImage h-full flex justify-center items-center '>
                    <img src="/Assets/loginpic.png" alt="" className='max-w-full p-5' />
                </div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={userRegisterFormSchema}
                    onSubmit={(values, { resetForm }) => {
                        handleSubmit(values, { resetForm });
                    }}
                >
                    {({ values, errors, touched }) => (
                        <div className='frontImage h-full flex justify-center items-center py-3'>
                            <Form autoComplete='off'>
                                <div className='flex flex-col gap-3 justify-center items-center  px-10'>
                                    <h3 className='font-semibold text-5xl text-t  text-center mb-5'>
                                        <span className='gradientText bg-clip-text text-3xl '>Sign Up</span>
                                    </h3>
                                    <div className='flex sm:flex-row flex-col gap-3'>
                                        <div className='flex flex-col w-full'>
                                            <p className='lightYellowText text-lg '>Name</p>
                                            <Field
                                                type="text"
                                                name="name"
                                                placeholder='Enter Name'
                                                className='inputBoxBorder rounded-md inputBoxBg text-white w-full py-2 px-5 mb-1'
                                            />
                                            <div className="errorMsg">
                                                <p className="m-0">{errors.name && touched.name ? errors.name : null}</p>
                                            </div>
                                        </div>
                                        <div className='flex flex-col w-full'>
                                            <p className='lightYellowText text-lg '>Email</p>
                                            <Field
                                                type="email"
                                                name="email"
                                                placeholder='Enter Email'
                                                className='inputBoxBorder rounded-md inputBoxBg text-white w-full py-2 px-5 mb-1'
                                            />
                                            <div className="errorMsg">
                                                <p className="m-0">{errors.email && touched.email ? errors.email : null}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex sm:flex-row flex-col gap-3 '>
                                        <div className='flex flex-col w-full '>
                                            <p className='lightYellowText text-lg'>Country Code</p>
                                            <Field
                                                type="text"
                                                name="countryCode"
                                                value={countryList.find(country => country.id === countryId)?.phonecode || ''}
                                                readOnly
                                                className='inputBoxBorder rounded-md inputBoxBg  text-white w-full sm:w-[15.5rem] py-2 text-center mb-1'
                                            />
                                        </div>
                                        <div className='flex flex-col w-full'>
                                            <p className='lightYellowText text-lg'>Mobile No.</p>
                                            <Field
                                                type="tel"
                                                name="mobile_no"
                                                placeholder='Enter Mobile No.'
                                                className='inputBoxBorder rounded-md inputBoxBg text-white w-full py-2 px-5 mb-1'
                                            />
                                            <div className="errorMsg">
                                                <p className="m-0 ">{errors.mobile_no && touched.mobile_no ? errors.mobile_no : null}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex sm:flex-row flex-col gap-3'>
                                        <div className='w-full'>
                                            <div className="flex flex-col w-full">
                                                <p className='lightYellowText text-lg'>Password</p>
                                                <Field name="password">
                                                    {({ field }) => (
                                                        <Input.Password
                                                            {...field}
                                                            className="inputBoxBorder rounded-md inputBoxBg text-white 
                                                            sm:w-[15.5rem] w-full py-2 px-5 mb-1"
                                                            placeholder='Enter Password'
                                                        />
                                                    )}
                                                </Field>
                                                {/* <Field
                                                    type={showPassword ? 'text' : 'password'}
                                                    name="password"
                                                    placeholder='Enter Password'
                                                    className='inputBoxBorder rounded-md inputBoxBg text-white w-full py-2 ps-5 pe-7 mb-1'
                                                />
                                                <img src={`/Assets/${showPassword ? 'eye' : 'View_hide'}.png`}
                                                    alt=""
                                                    onClick={e => setShowPassword(!showPassword)}
                                                    className={`absolute ${showPassword ? 'top-[58%] right-[5px]' : 'top-[52%] right-[3px]'} right-3 cursor-pointer `}
                                                /> */}
                                            </div>
                                            <div className="errorMsg">
                                                <p className="m-0">{errors.password && touched.password ? errors.password : null}</p>
                                            </div>
                                        </div>
                                        <div className='flex flex-col w-full'>
                                            <p className='lightYellowText text-lg '>Shop Name</p>
                                            <Field
                                                type="text"
                                                name="shop_name"
                                                placeholder='Enter Shop Name'
                                                className='inputBoxBorder rounded-md inputBoxBg text-white w-full py-2 px-5 mb-1'
                                            />
                                            <div className="errorMsg">
                                                <p className="m-0">{errors.shop_name && touched.shop_name ? errors.shop_name : null}</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='flex sm:flex-row flex-col gap-3 '>
                                        <div className='flex flex-col w-full'>
                                            <p className='lightYellowText text-lg'>Shop Address</p>
                                            <Field
                                                type="text"
                                                name="shop_address"
                                                placeholder='Enter Shop Address'
                                                className='inputBoxBorder rounded-md inputBoxBg text-white w-full py-2 px-5 mb-1'
                                            />
                                            <div className="errorMsg">
                                                <p className="m-0">{errors.shop_address && touched.shop_address ? errors.shop_address : null}</p>
                                            </div>
                                        </div>

                                        <div className='flex flex-col w-full '>
                                            <p className='lightYellowText text-lg'>Country</p>
                                            <Select
                                                name='country'
                                                options={countryList.map(country => ({ value: country.id, label: country.name }))}
                                                onChange={handleCountry}
                                                value={countryId}
                                                className='h-11 w-full sm:w-[15.5rem] min-w-[15.5rem] max-w-[15.5rem] mb-1'
                                            />
                                        </div>
                                    </div>
                                    <div className='flex sm:flex-row flex-col gap-3'>
                                        <div className='flex flex-col w-full'>
                                            <p className='lightYellowText text-lg'>State</p>
                                            <Select
                                                name='state'
                                                options={Array.isArray(stateList) ? stateList.map(state => ({ value: state.id, label: state.name })) : []}
                                                onChange={handleState}
                                                value={stateId}
                                                className='h-11  w-full sm:w-[15.5rem] min-w-[15.5rem] max-w-[15.5rem] mb-1'
                                            />
                                        </div>
                                        <div className='flex flex-col w-full'>
                                            <p className='lightYellowText text-lg'>City</p>
                                            <Select
                                                name='state'
                                                options={Array.isArray(cityList) ? cityList.map(city => ({ value: city.id, label: city.name })) : []}
                                                onChange={e => setCityId(e)}
                                                value={cityId}
                                                className='h-11  w-full sm:w-[15.5rem] min-w-[15.5rem] max-w-[15.5rem] mb-1'
                                            />
                                        </div>
                                    </div>
                                    <div className='w-full text-center'>
                                        <button type="submit" className='btnGradient sm:w-full w-[15.5rem] rounded-md text-[white]  font-normal text-lg py-2 mt-1'>Register
                                        </button>
                                    </div>
                                    <div className='flex gap-2 mt-2'>
                                        <p className='gradientText text-nowrap'>You have already an Account?
                                        </p>
                                        <button onClick={() => navigate('/login')} className='lightYellowText underline'>Login</button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default SignUp;
