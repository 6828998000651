import React, { useContext } from 'react';
import Sidebar from './Components/Sidebar';
import { SidebarContext } from './Context'


const MainLayout = ({ children }) => {
    const { showsideBar } = useContext(SidebarContext)
    return (
        <>
           <Sidebar />
            <div className={`${showsideBar ? 'md:ml-28  px-5' : ' md:ml-60 px-5'} `} style={{ transition: "all 0.6s" }} >            
                <div className='text-white py-5'>
                    {children}
                </div>
            </div>
        </>
    )
}

export default MainLayout;
