import { Image, Switch, Tooltip } from "antd";
import moment from "moment/moment";


const getRowNumber = (currentPage, index) => {
    return (currentPage - 1) * 10 + index + 1;
};

const news_columns = (handleStatus, handleRemoveId, handleEditNews, currentPage) => [
    {
        title: 'No.',
        dataIndex: 'no',
        render: (text, record, index) => getRowNumber(currentPage, index),
    },
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        sorter: (a, b) => a.title?.localeCompare(b.title),
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        sorter: (a, b) => a.description?.localeCompare(b.description),
        render: (text) => (
            <Tooltip title={text} overlayStyle={{backgroundColor: '#f6da6d'}}>
                {text.length > 40 ? `${text.substring(0, 40)}....` : text}
            </Tooltip>
        ),
    },
    {
        title: 'Image',
        dataIndex: 'image',
        key: 'image',
        render: (text, record) => (
            <Image src={text} alt="" style={{ borderRadius: '50%', width: '30px', height: '30px' }} />
        ),
    },
    // {
    //     title: 'Status',
    //     dataIndex: 'status',
    //     key: 'status',
    //     sorter: (a, b) => a.status?.localeCompare(b.status),
    //     render: (text) => (<div className={`${text === 'active' ? 'text-green-400' : 'text-red-400'}`}>{text}</div>)
    // },
    {
        title: 'UpDated At',
        dataIndex: 'updated_at',
        key: 'updated_at',
        sorter: (a, b) => moment(a.updated_at) - moment(b.updated_at),
        render: (text) => <div>{moment(text).format('lll')}</div>
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'changestatus',
        sorter: (a, b) => a.status.localeCompare(b.status),
        render: (text, record) => {
            const newsStatus = record.status === 'active' ? 'inactive' : 'active';
            const isActive = record.status === 'active';
            return (
                <>
                    <Switch
                        checked={isActive}
                        onChange={() => handleStatus(record.id, isActive ? 'inactive' : 'active')}
                        checkedChildren="Active"
                        unCheckedChildren="InActive"
                    />
                </>
            );
        },
    },
    // {
    //     title: 'Edit',
    //     key: 'edit',
    //     render: (record) => (<button onClick={() => handleEditNews(record.id)}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="fill-[#05b3f8] h-4 w-4"><path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z" /></svg></button>)
    // },
    {
        title: 'Action',
        key: 'action',
        render: (record) => (<  >
            <button onClick={() => handleRemoveId(record?.id)} className="me-3"><img src="/Assets/delete.svg" alt="" /></button>
            <button onClick={() => handleEditNews(record.id)} ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="fill-[#05b3f8] h-4 w-4"><path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z" /></svg></button>
        </>)
    },
]
export default news_columns;
