
import { Image } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";

const getRowNumber = (currentPage, index) => {
    return (currentPage - 1) * 10 + index + 1;
};

const kycList_columns = (currentPage, handleAcceptData, handleRejectData, kycStatus) => {
    const column = [
        {
            title: 'No.',
            dataIndex: 'no',
            render: (text, record, index) => getRowNumber(currentPage, index),
        },
        {
            title: 'Name',
            dataIndex: 'client',
            key: 'name',
            sorter: (a, b) => a.client?.first_name?.localeCompare(b.client?.first_name),
            render: (text, record) => (<Link to={`/clientdetail/${record.client_id}`} className="darkYellowText hover:text-[#f6da6d]">{text?.first_name} {text?.last_name}</Link>)
        },
        {
            title: 'Email',
            dataIndex: 'client',
            key: 'email',
            sorter: (a, b) => a.client?.email?.localeCompare(b.client?.email),
            render: (record) => record?.email,
        },
        {
            title: 'Mobile',
            dataIndex: 'client',
            key: 'mobile_no',
            sorter: (a, b) => a.client?.mobile_no?.localeCompare(b.client?.mobile_no),
            render: (record) => record?.mobile_no,
        },
        {
            title: 'Document Type',
            dataIndex: 'document_type',
            key: 'document_type',
            sorter: (a, b) => a.document_type?.localeCompare(b.document_type),
        },
        {
            title: 'Document Number',
            dataIndex: 'document_number',
            key: 'document_number',
            sorter: (a, b) => a.document_number?.localeCompare(b.document_number),
        },
        {
            title: 'Document Image',
            dataIndex: 'document_img',
            key: 'document_img',
            render: (text, record) => (
                <Image src={text} alt="Preview" style={{ borderRadius: '50%', width: '30px', height: '30px' }} />
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) => {
                let color, backgroundColor;
                if (text === 'Pending' || text === 'pending') {
                    color = '#F0A11B';
                    backgroundColor = '#f0a11b3b';
                }
                else if (text === 'Completed' || text === 'completed') {
                    color = '#009900';
                    backgroundColor = '#00800040';
                }
                else if (text === 'Reject' || text === 'reject') {
                    color = '#E20001';
                    backgroundColor = '#e2000140';
                }
                return (
                    <div style={{ color, backgroundColor, borderRadius: '4px' }}>{text.toUpperCase()}</div>
                )
            }
        },
    ];
    if (kycStatus === 'pending') {
        column.push({
            title: 'Action',
            key: 'action',
            render: (record) => (
                <div className="flex ">
                    <div className="mx-1 cursor-pointer" onClick={() => handleAcceptData(record?.client_id, record?.id)}
                    >
                        <img src="/Assets/accept_icon.svg" alt="" />
                    </div>
                    <div className="mx-1 cursor-pointer" onClick={() => handleRejectData(record?.client_id, record?.id)}
                    >
                        <img src="/Assets/delete_icon.svg" alt="" />
                    </div>
                </div>
            ),
        })
    }
    return column;
};
export default kycList_columns;