import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SidebarContext } from '../Context';
import Logout from './Logout';

export default function Sidebar() {
    let location = useLocation();

    const [isOpen, setIsOpen] = useState(false);
    const { showsideBar, setshowSideBar } = useContext(SidebarContext);
    const [isTransactionOpen, setIsTransactionOpen] = useState(false);
    const transactionContentRef = useRef(null);

    useEffect(() => {
        if (isTransactionOpen) {
            transactionContentRef.current.style.height = `${transactionContentRef.current.scrollHeight}px`;
            transactionContentRef.current.style.opacity = '1';
        } else {
            transactionContentRef.current.style.height = '0px';
            transactionContentRef.current.style.opacity = '0';
        }
    }, [isTransactionOpen]);

    return (
        <div className={` relative `}>
            {/* Sidebar */}
            <div className={`sidebar-bg sidebar-container fixed h-full px-2 text-white z-10  ${showsideBar ? 'left-0 ' : 'md:left-0 -left-96'}`}
                style={{ whiteSpace: 'nowrap', transition: "all 0.7s" }}>
                <button className='absolute top-2 right-4 md:hidden' onClick={() => setshowSideBar(!showsideBar)}>x</button>
                <div className='flex gap-3 px-5 py-6 mb-4'>
                    <img src="/Assets/ApexBullion.svg" alt="" />
                    <span className={`text-lg font-semibold ${showsideBar ? 'md:w-0 overflow-hidden' : ''}`} >Apex Bullion </span>
                </div>
                <ul>
                    <Link to='/home'>
                        <li
                            onClick={() => setshowSideBar(false)}
                            className={`flex items-center text-sm font-normal mb-6 h-[32px] px-5 py-5 hover:bg-[#ba8c2d] rounded-xl
                            ${location.pathname === '/home' ? 'bgGradient font-semibold border-2 borderStyle rounded-xl' : ''}`}>
                            <img src="/Assets/Home.svg" alt="" className="sm:mr-2 mr-1 my-[20px] " />
                            <span className={`${showsideBar ? 'md:w-0 overflow-hidden ' : ''}`} >
                                Dashboard
                            </span>
                        </li>
                    </Link>
                    <li className="flex flex-col">
                        <div
                            onClick={() => setIsTransactionOpen(!isTransactionOpen)}
                            className={`flex items-center text-sm font-normal mb-6 h-[32px] px-5 py-5 hover:bg-[#ba8c2d] rounded-xl cursor-pointer`}>
                            <img src="/Assets/Transaction.svg" alt="" className="sm:mr-2 mr-1 my-[20px]" />
                            <span className={`${showsideBar ? 'md:w-0 overflow-hidden' : ''}`}>Transaction</span>
                            <span className="text-xl ml-auto">{isTransactionOpen ? '▾' : '▸'}</span>
                        </div>
                        <div ref={transactionContentRef} className="transition-content">
                            {isTransactionOpen && (
                                <ul>
                                    <Link to='/depositmanager'>
                                        <li
                                            onClick={() => setshowSideBar(false)}
                                            className={`flex items-center ms-4 px-3 text-sm font-normal mb-4 h-[32px] py-5 hover:bg-[#ba8c2d] rounded-xl ${location.pathname === '/depositmanager' ? 'bgGradient font-semibold border-2 borderStyle rounded-xl' : ''}`}>
                                            <img src="/Assets/DepositManager.svg" alt="" className="mr-1 my-[20px]" />
                                            <span className={`${showsideBar ? 'md:w-0 overflow-hidden' : ''}`}>Deposit Manager</span>
                                        </li>
                                    </Link>
                                    <Link to='/withdrawmanager'>
                                        <li
                                            onClick={() => setshowSideBar(false)}
                                            className={`flex items-center ms-4 px-3 text-sm font-normal mb-4 h-[32px] py-5 hover:bg-[#ba8c2d] rounded-xl ${location.pathname === '/withdrawmanager' ? 'bgGradient font-semibold border-2 borderStyle rounded-xl' : ''}`}>
                                            <img src="/Assets/WithdrawManager.svg" alt="" className="mr-1 my-[20px]" />
                                            <span className={`${showsideBar ? 'md:w-0 overflow-hidden' : ''}`}>Withdraw Manager</span>
                                        </li>
                                    </Link>
                                    <Link to='/buysellmanager'>
                                        <li
                                            onClick={() => setshowSideBar(false)}
                                            className={`flex items-center ms-4 px-3 text-sm font-normal mb-4 h-[32px] py-5 hover:bg-[#ba8c2d] rounded-xl ${location.pathname === '/buysellmanager' ? 'bgGradient font-semibold border-2 borderStyle rounded-xl' : ''}`}>
                                            <img src="/Assets/BuySellManager.svg" alt="" className="sm:mr-2 mr-1 my-[20px]" />
                                            <span className={`${showsideBar ? 'md:w-0 overflow-hidden' : ''}`}>BuySell Manager</span>
                                        </li>
                                    </Link>
                                </ul>
                            )}
                        </div>
                    </li>
                    <Link to='/kyclist'>
                        <li
                            onClick={() => setshowSideBar(false)}
                            className={`flex items-center text-sm font-normal mb-6 h-[32px] px-5 py-5 hover:bg-[#ba8c2d] rounded-xl 
                            ${location.pathname === '/kyclist' ? 'bgGradient font-semibold border-2 borderStyle rounded-xl' : ''}`}>
                            <img src='/Assets/Kyc.svg' alt="" className="sm:mr-2 mr-2 my-[20px]" />
                            <span className={`${showsideBar ? 'md:w-0 overflow-hidden ' : ''}`} >
                                KYC
                            </span>
                        </li>
                    </Link>
                    <Link to='/clientmanager'>
                        <li
                            onClick={() => setshowSideBar(false)}
                            className={`flex items-center text-sm font-normal mb-6 h-[32px] px-5 py-5 hover:bg-[#ba8c2d] rounded-xl
                            ${location.pathname === '/clientmanager' ? 'bgGradient font-semibold border-2 borderStyle rounded-xl' : ''}`}>
                            <img src="/Assets/ClientManager.svg" alt="" className="sm:mr-2 mr-1 my-[20px] " />
                            <span className={`${showsideBar ? 'md:w-0 overflow-hidden ' : ''}`} >
                                Client Manager
                            </span>
                        </li>
                    </Link>
                    <Link to='/goldportfolio'>
                        <li
                            onClick={() => setshowSideBar(false)}
                            className={`flex items-center text-sm font-normal mb-6 h-[32px] px-5 py-5 hover:bg-[#ba8c2d] rounded-xl 
                            ${location.pathname === '/goldportfolio' ? 'bgGradient font-semibold border-2 borderStyle rounded-xl' : ''}`}>
                            <img src='/Assets/GoldPortfolio.svg' alt="" className="sm:mr-2 mr-2 my-[20px]" />
                            <span className={`${showsideBar ? 'md:w-0 overflow-hidden ' : ''}`} >
                                Gold Portfolio
                            </span>
                        </li>
                    </Link>

                    <Link to='/deliverymanager'>
                        <li
                            onClick={() => setshowSideBar(false)}
                            className={`flex items-center text-sm font-normal mb-6 h-[32px] px-5 py-5 hover:bg-[#ba8c2d] rounded-xl 
                            ${location.pathname === '/deliverymanager' ? 'bgGradient font-semibold border-2 borderStyle rounded-xl' : ''}`}>
                            <img src='/Assets/DeleveryManager.svg' alt="Setting" className="sm:mr-2 mr-2 my-[20px]" />
                            <span className={`${showsideBar ? 'md:w-0 overflow-hidden ' : ''}`} >
                                Delivery Manager
                            </span>
                        </li>
                    </Link>
                    <Link to='/reportmanager'>
                        <li
                            onClick={() => setshowSideBar(false)}
                            className={`flex items-center text-sm font-normal mb-6 h-[32px] px-5 py-5 hover:bg-[#ba8c2d] rounded-xl 
                            ${location.pathname === '/reportmanager' ||
                                    location.pathname === '/kycreport' ||
                                location.pathname === '/clientreport' ||
                                    location.pathname === '/deliveryreport' ||
                                    location.pathname === '/depositreport' ||
                                    location.pathname === '/withdrawreport'
                                    ? 'bgGradient font-semibold border-2 borderStyle rounded-xl' : ''}`}>
                            <img src='/Assets/ReportManager.svg' alt="" className="sm:mr-2 mr-2 my-[20px]" />
                            <span className={`${showsideBar ? 'md:w-0 overflow-hidden ' : ''}`} >
                                Report Manager
                            </span>
                        </li>
                    </Link>
                    <Link to='/news'>
                        <li
                            onClick={() => setshowSideBar(false)}
                            className={`flex items-center text-sm font-normal mb-6 h-[32px] px-5 py-5 hover:bg-[#ba8c2d] rounded-xl 
                            ${location.pathname === '/news' ? 'bgGradient font-semibold border-2 borderStyle rounded-xl' : ''}`}>
                            <img src='/Assets/News.svg' alt="" className="sm:mr-2 mr-2 my-[20px]" />
                            <span className={`${showsideBar ? 'md:w-0 overflow-hidden ' : ''}`} >
                                News
                            </span>
                        </li>
                    </Link>
                    <Link to='/support'>
                        <li
                            onClick={() => setshowSideBar(false)}
                            className={`flex items-center text-sm font-normal mb-6 h-[32px] px-5 py-5 hover:bg-[#ba8c2d] rounded-xl 
                            ${location.pathname === '/support' ? 'bgGradient font-semibold border-2 borderStyle rounded-xl' : ''}`}>
                            <img src='/Assets/Support.svg' alt="" className="sm:mr-2 mr-2 my-[20px]" />
                            <span className={`${showsideBar ? 'md:w-0 overflow-hidden ' : ''}`} >
                                support
                            </span>
                        </li>
                    </Link>
                    <Link to='/notification'>
                        <li
                            onClick={() => setshowSideBar(false)}
                            className={`flex items-center text-sm font-normal mb-6 h-[32px] px-5 py-5 hover:bg-[#ba8c2d] rounded-xl 
                            ${location.pathname === '/notification' ? 'bgGradient font-semibold border-2 borderStyle rounded-xl' : ''}`}>
                            <img src='/Assets/Notification.svg' alt="" className="sm:mr-2 mr-2 my-[20px]" />
                            <span className={`${showsideBar ? 'md:w-0 overflow-hidden ' : ''}`} >
                                Notification
                            </span>
                        </li>
                    </Link>
                    <Link to='/setting'>
                        <li
                            onClick={() => setshowSideBar(false)}
                            className={`flex items-center text-sm font-normal mb-6 h-[32px] px-5 py-5 hover:bg-[#ba8c2d] rounded-xl 
                            ${location.pathname === '/setting' ? 'bgGradient font-semibold border-2 borderStyle rounded-xl' : ''}`}>
                            <img src='/Assets/Setting.svg' alt="" className="sm:mr-2 mr-2 my-[20px]" />
                            <span className={`${showsideBar ? 'md:w-0 overflow-hidden ' : ''}`} >
                                Setting
                            </span>
                        </li>
                    </Link>

                    <div className="cursor-pointer" onClick={e => setIsOpen(true)}>
                        <li
                            className={`flex items-center text-sm font-normal mb-6 h-[32px] px-5 py-5 hover:bg-[#ba8c2d] rounded-xl
                            ${location.pathname === '/logout' ? 'bgGradient font-semibold border-2 borderStyle rounded-xl ' : ''}`}>
                            <img src='/Assets/LogoutIcon.svg' alt="" className="sm:mr-2 mr-2 my-[20px]" /><span className={`${showsideBar ? 'md:w-0 overflow-hidden' : ''}`}>
                                Logout
                            </span>
                        </li>
                    </div>
                </ul>
                <Logout isOpen={isOpen} setIsOpen={setIsOpen} />
            </div>
        </div>
    )
}
