import React, { useEffect, useState } from 'react';
import Navbar from '../Components/Navbar';
import ApiClient from '../Services/ApiServices/ApiClinet';
import { toast } from 'react-toastify';
import { DatePicker, Pagination, Table } from 'antd';
import clientReportColumns from '../Columns/ReportManager/clientReport';
import loader from '../Services/Loader/Loader';
import { CSVLink } from 'react-csv';
import BackButton from '../Components/BackButton';

export default function ClientReport() {
    const [clientReportData, setClientReportData] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [submit, setSubmit] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [csvData, setCsvData] = useState([]);
    const [isExport, setIsExport] = useState(false);


    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleSubmit = () => {
        setSubmit(true);
    };

    const getClientReport = (page) => {
        loader(true);
        ApiClient.post('clientreport', { start_date: startDate, end_date: endDate, is_export: 0, page })
            .then((response) => {
                if (response.data) {
                    setClientReportData(response.data);
                }
                else {
                    toast.error(response.message);
                }
                loader(false);
            })
            .catch(error => {
                toast.error(error.message);
                loader(false);
            })
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }
    const handleExport = () => {
        setIsExport(true);
        loader(true);
        ApiClient.post('clientreport', { start_date: startDate, end_date: endDate, is_export: 1 })
            .then((response) => {
                if (response?.status) {
                    setCsvData(response?.data);
                    toast.success('Export data successfully..');
                } else {
                    toast.error(response.message);
                }
                loader(false);
                setIsExport(false);
            })
            .catch(error => {
                toast.error(error.message);
                loader(false);
                setIsExport(false);
            });
    };

    useEffect(() => {
        if (csvData.length > 0) {
            document.getElementById('csv-download-link').click();
        }
    }, [csvData]);

    useEffect(() => {
        if (submit && startDate && endDate) {
            getClientReport(currentPage);
            setSubmit(false);
        }
        document.title = 'Apex Bullion-Client Report'
    }, [submit, startDate, endDate, currentPage]);

    return (
        <>
            <Navbar currentPage={'Client Report'} />
            <div className='flex  sm:flex-row flex-col justify-between sm:items-end gap-2 mt-5'>
                <div className='flex gap-3'>
                    <BackButton />
                    {clientReportData.length > 0 && (
                        <>
                            <button
                                className='btnGradient py-1 px-3 rounded-md hover:scale-105 duration-300 flex gap-2'
                                onClick={handleExport}
                                disabled={isExport}
                            >
                                <img src="/Assets/Export.svg" alt="" />
                                EXPORT
                            </button>
                            <CSVLink
                                data={csvData}
                                filename={'client_report.csv'}
                                id='csv-download-link'
                                className='hidden'
                            />
                        </>
                    )}
                </div>
                <div className='flex  gap-5'>
                    <div className='flex flex-col'>
                        <label htmlFor="sdate">Start Date</label>
                        <DatePicker format={'DD-MM-YYYY'} onChange={handleStartDateChange} className='py-1'
                            disabledDate={current => { return current && (current > new Date() || current > new Date(endDate)); }}
                        />
                    </div>
                    <div className='flex flex-col'>
                        <label htmlFor="edate">End Date</label>
                        <DatePicker format={'DD-MM-YYYY'} onChange={handleEndDateChange} className='py-1'
                            disabledDate={current => { return current && (current > new Date() || current < new Date(startDate)); }}
                        />
                    </div>
                    <div className='mt-auto'>
                        <button onClick={handleSubmit}
                            className='btnGradient py-1 px-3 rounded-md hover:scale-105 duration-300'>
                            Submit
                        </button>
                    </div>
                </div>
            </div>
            <div className=" overflow-x-auto shadow-md darkBg rounded-lg border-2 borderStyle mt-4">
                <Table dataSource={clientReportData} columns={clientReportColumns(currentPage)} pagination={false} />
            </div>
            <div className='text-right mt-5'>
                <Pagination current={currentPage} total={totalPages} onChange={handlePageChange} />
            </div>
        </>
    )
}
