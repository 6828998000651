
import { Link } from "react-router-dom";

const getRowNumber = (currentPage, index) => {
    return (currentPage - 1) * 10 + index + 1;
};

const goldPortfolio_columns = (currentPage) => [
    {
        title: 'No.',
        dataIndex: 'no',
        render: (text, record, index) => getRowNumber(currentPage, index),
    },
    {
        title: 'Name',
        dataIndex: 'clients',
        key: 'name',
        sorter: (a, b) => a.clients?.first_name?.localeCompare(b.clients?.first_name),
        render: (text, record) => (<Link to={`/clientdetail/${record.client_id}`} className="darkYellowText hover:text-[#f6da6d]">{text?.first_name} {text?.last_name}</Link>)
    },
    {
        title: 'Mobile',
        dataIndex: 'clients',
        key: 'mobile_no',
        sorter: (a, b) => a.clients?.mobile_no?.localeCompare(b.clients.mobile_no),
        render: (record) => record?.mobile_no
    },
    {
        title: 'Email',
        dataIndex: 'clients',
        key: 'email',
        sorter: (a, b) => a.clients?.email?.localeCompare(b.clients.email),
        render: (record) => record?.email
    },
    {
        title: 'Country',
        dataIndex: 'clients',
        key: 'country',
        sorter: (a, b) => a.clients?.country?.localeCompare(b.clients?.country),
        render: (record) => record?.country
    },
    {
        title: 'Quantity',
        dataIndex: 'total_gold',
        key: 'quantity',
        render:(text)=>(Number(text).toFixed(4))
    },
    {
        title:'Rate',
        dataIndex:'gold_price',
        key:'gold_price',
        sorter: (a, b) => a.gold_price?.localeCompare(b.gold_price),
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        sorter: (a, b) => a.type?.localeCompare(b.type),
    },

]
export default goldPortfolio_columns;