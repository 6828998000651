import React, { useEffect, useState } from 'react';
import Navbar from '../Components/Navbar';
import { Pagination, Radio, Table } from 'antd';
import support from '../Columns/Support/support';
import ApiClient from '../Services/ApiServices/ApiClinet';
import { toast } from 'react-toastify';
import loader from '../Services/Loader/Loader';


export default function Support() {
  const [supportData, setSupportData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [statusFilter, setStatusFilter] = useState('open');

  const getData = (page) => {
    loader(true);
    ApiClient.post('supportlist', { status: statusFilter, page })
      .then((response) => {
        if (response.status) {
          setSupportData(response?.data);
          setTotalPages(response?.total_record);
        }
        else {
          toast.error(response?.message);
        }
        loader(false);
      })
      .catch(error => {
        toast.error(error.message);
        loader(false);
      })
  }
  const handlePageChange = (page) => {
    setCurrentPage(page);
  }
  useEffect(() => {
    getData(currentPage);
    document.title = 'Apex Bullion-Support'
  }, [currentPage, statusFilter]);

  useEffect(() => {
    setCurrentPage(1)
}, [statusFilter]);

  return (
    <>
      <Navbar currentPage='Support' />
      <div className='flex justify-end mt-4'>
        <Radio.Group value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)} buttonStyle="solid" className='flex'>
          <Radio.Button className='text-nowrap text-center' value="open">Open</Radio.Button>
          <Radio.Button className='text-nowrap text-center' value="close">Close</Radio.Button>
        </Radio.Group>
      </div>
      <div className="relative overflow-x-auto shadow-md darkBg rounded-lg border-2 borderStyle mt-4">
        <Table dataSource={supportData} columns={support(currentPage)} className='darkBg ' pagination={false} />
      </div>
      <div className='text-right mt-5'>
        <Pagination current={currentPage} total={totalPages} onChange={handlePageChange} />
      </div>
    </>
  )
}
