
import React, { createContext, useState } from "react";

export const SidebarContext = createContext();

export const SidebarProviderContext = ({ children }) => {
    const [showsideBar, setshowSideBar] = useState(false);    
    return (
        <SidebarContext.Provider value={{ showsideBar, setshowSideBar}}>
            {children}
        </SidebarContext.Provider >
    )
}
