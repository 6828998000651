import React, { useEffect, useRef, useState } from 'react';
import { Table, Radio, Pagination, Input, Image, Select } from 'antd';
import Navbar from '../Components/Navbar';
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import ApiClient from '../Services/ApiServices/ApiClinet';
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";
import loader from '../Services/Loader/Loader.jsx';
import ModalComponent from '../Components/Modal.jsx';
import clientManager_column from '../Columns/ClientManager/clientManager_column.js';
import moment from 'moment';
import Dragger from 'antd/es/upload/Dragger.js';
import { InboxOutlined } from '@ant-design/icons';

export default function ClientManager() {
  const user = useSelector((state) => state.user);
  const [userList, setUserList] = useState([]);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [active, setActive] = useState('0');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [draggerKey, setDraggerKey] = useState(Date.now());
  const [imagePreview, setImagePreview] = useState(null);
  const [documentType, setDocumentType] = useState('');

  const formikRef = useRef();

  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    mobile_no: '',
    address: '',
    id_number: '',
    trn_number: '',
    trade_license: '',
    country: '',
    city: '',
    document_number: '',
  };

  const FormSchema = Yup.object().shape({
    first_name: Yup.string()
      .trim()
      .required("Please enter first name."),
    last_name: Yup.string()
      .trim()
      .required("Please enter last name."),
    email: Yup.string()
      .email("Please enter your Valid email ID.")
      .trim()
      .required("Please enter your email ID."),
    password: Yup.string()
      .trim()
      .min(8, "Password must be at least 8 characters")
      .matches(/[a-z]/, "Enter at least one lowercase letter")
      .matches(/[A-Z]/, "Enter at least one uppercase letter")
      .matches(/[0-9]/, "Enter at least one number")
      .matches(/[@$!%*?&#]/, "Enter at least one special character")
      .required("Please enter your password"),
    mobile_no: Yup.string()
      .matches(/^\d+$/, 'Mobile no. contain only numbers')
      .matches(/^\d{7,15}$/, 'Mobile no. between 7 to 15 digits')
      .trim()
      .required("Please enter your mobile number"),
    address: Yup.string()
      .trim()
      .required("Please enter your address."),
    id_number: Yup.string()
      .trim()
      .required('Please enter id number.'),
    trn_number: Yup.string()
      .trim()
      .required('Please enter transaction number.'),
    trade_license: Yup.string()
      .trim()
      .required('Please enter trade license number.'),
    country: Yup.string()
      .trim()
      .required('Please enter country name.'),
    city: Yup.string()
      .trim()
      .required('Please enter city name.'),
    document_number: Yup.string()
      .trim()
      .required('Please enter document number.'),
  });

  const handleSubmit = (values, { resetForm }) => {
    ApiClient.postFormData('addclient', { ...values, document_type: documentType, document_img: imageFile })
      .then((response) => {
        if (response.status) {
          resetForm();
          getData();
          toast.success(response?.message);
          setIsAddOpen(false);
        } else {
          toast.error(response?.message);
        }
      })
      .catch(err => {
        toast.error(err.message)
        loader(false);
      });
  }

  const getData = (page) => {
    loader(true);
    ApiClient.post('getclientlist', { status: active, page })
      .then((response) => {
        if (response.status) {
          setUserList(response.data);
          setTotalPages(response.total_record);
        }
        loader(false);
      })
      .catch(err => {
        toast.error(err.message);
        loader(false);
      });
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  }

  const searchUserList = userList.filter((item) => {
    const formatCreatedAt = moment(item.created_at).format('lll');
    return (
      item.name?.toLowerCase().includes(search.toLowerCase()) ||
      item.mobile_no?.toLowerCase().includes(search.toLowerCase()) ||
      item.email?.toLowerCase().includes(search.toLowerCase()) ||
      item.passport_number?.toLowerCase().includes(search.toLowerCase()) ||
      item.kyc_status?.toLowerCase().includes(search.toLowerCase()) ||
      formatCreatedAt?.toLowerCase().includes(search.toLowerCase()) ||
      item.ftd_status?.toLowerCase().includes(search.toLowerCase())
    )
  });

  const handleUpload = (info) => {
    const fileList = info.fileList;
    if (fileList && (fileList.length > 0)) {
      setImageFile(fileList[0].originFileObj);
      setImagePreview(URL.createObjectURL(fileList[0].originFileObj));
    }
  };

  useEffect(() => {
    if (!isAddOpen && formikRef.current) {
      formikRef.current.resetForm();
      setImageFile(null);
      setImagePreview(null);
      setDocumentType('');
    }
    if (!isAddOpen) {
      setDraggerKey(Date.now());
    }
  }, [isAddOpen]);

  useEffect(() => {
    getData(currentPage);
    document.title = 'Apex Bullion-Client Manager'
  }, [currentPage, active]);

  useEffect(() => {
    setCurrentPage(1)
  }, [active])

  return (
    <>
      <Navbar currentPage='Client Manager' />
      <div className='flex sm:flex-row flex-col gap-2 justify-between mt-3'>
        <div>
          <Radio.Group value={active} onChange={(e) => setActive(e.target.value)} buttonStyle="solid">
            <Radio.Button className=' sm:text-[14px] text-[10px]' value="0">Active</Radio.Button>
            <Radio.Button className=' sm:text-[14px] text-[10px]' value="1">InActive</Radio.Button>
          </Radio.Group>
        </div>
        <div className='flex gap-5'>
          <div className=' relative'>
            <input type="text"
              placeholder='Search...'
              className='darkBg px-6 py-1 rounded-md focus:outline-none'
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='h-4 fill-[#c0c0c071] absolute top-2 left-1'><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" /></svg>
          </div>
          <div>
            <button type="button" onClick={() => setIsAddOpen(true)} className=" btnGradient rounded-md font-semibold flex items-center py-1 px-2 gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill='white' width="20"
                height="20"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" /></svg>
              Add Client
            </button>
          </div>
        </div>
      </div>
      <ModalComponent isOpen={isAddOpen} setIsOpen={setIsAddOpen} title='Add Client' width='75%'>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={FormSchema}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values, { resetForm });
          }}
        >
          {({ errors, touched }) => (

            <Form className="darkBg border-2 borderStyle rounded-md px-5 py-7" autoComplete='off'>
              <div className=' grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-3'>
                <div >
                  <p className="block lightYellowText font-medium text-sm ">First Name</p>
                  <Field type="text" name="first_name" placeholder='Enter Fisrt Name' className="w-full inputBoxBorder inputBoxBg rounded-md font-normal text-sm py-2 px-3 mb-1" />
                  <div className="errorMsg"><p className="m-0">{errors?.first_name && touched?.first_name ? errors?.first_name : null}</p></div>
                </div>
                <div >
                  <p className="block lightYellowText font-medium text-sm ">Last Name</p>
                  <Field type="text" name="last_name" placeholder='Enter Fisrt Name' className="w-full inputBoxBorder inputBoxBg rounded-md font-normal text-sm py-2 px-3 mb-1" />
                  <div className="errorMsg"><p className="m-0">{errors?.last_name && touched?.last_name ? errors?.last_name : null}</p></div>
                </div>
                <div >
                  <p className="block lightYellowText font-medium text-sm ">Mobile</p>
                  <Field type="text" name="mobile_no" placeholder='Enter Mobile number' className="w-full inputBoxBorder inputBoxBg rounded-md font-normal text-sm py-2 px-3 mb-1" />
                  <div className="errorMsg"><p className="m-0">{errors?.mobile_no && touched?.mobile_no ? errors?.mobile_no : null}</p></div>
                </div>

                <div >
                  <p className="block lightYellowText font-medium text-sm ">Address</p>
                  <Field type="text" name="address" placeholder='Enter Address' className="w-full inputBoxBorder inputBoxBg rounded-md font-normal text-sm py-2 px-3 mb-1" />
                  <div className="errorMsg"><p className="m-0">{errors?.address && touched?.address ? errors?.address : null}</p></div>
                </div>
                <div >
                  <p className="block lightYellowText font-medium text-sm ">City</p>
                  <Field type="text" name="city" placeholder='Enter City' className="w-full inputBoxBorder inputBoxBg rounded-md font-normal text-sm py-2 px-3 mb-1" />
                  <div className="errorMsg"><p className="m-0">{errors?.city && touched?.city ? errors?.city : null}</p></div>
                </div>

                <div >
                  <p className="block lightYellowText font-medium text-sm ">Country</p>
                  <Field type="text" name="country" placeholder='Enter Country' className="w-full inputBoxBorder inputBoxBg rounded-md font-normal text-sm py-2 px-3 mb-1" />
                  <div className="errorMsg"><p className="m-0">{errors?.country && touched?.country ? errors?.country : null}</p></div>
                </div>
                <div >
                  <p className="block lightYellowText font-medium text-sm ">Email</p>
                  <Field type="email" name="email" placeholder='Enter Email' className="w-full inputBoxBorder inputBoxBg rounded-md font-normal text-sm py-2 px-3 mb-1" />
                  <div className="errorMsg"><p className="m-0">{errors?.email && touched?.email ? errors?.email : null}</p></div>
                </div>

                <div >
                  <p className="block lightYellowText font-medium text-sm ">ID Number</p>
                  <Field type="text" name="id_number" placeholder='Enter ID Number' className="w-full inputBoxBorder inputBoxBg rounded-md font-normal text-sm py-2 px-3 mb-1" />
                  <div className="errorMsg"><p className="m-0">{errors?.id_number && touched?.id_number ? errors?.id_number : null}</p></div>
                </div>
                <div >
                  <p className="block lightYellowText font-medium text-sm ">TNR No</p>
                  <Field type="text" name="trn_number" placeholder='Enter TNR No' className="w-full inputBoxBorder inputBoxBg rounded-md font-normal text-sm py-2 px-3 mb-1" />
                  <div className="errorMsg"><p className="m-0">{errors?.trn_number && touched?.trn_number ? errors?.trn_number : null}</p></div>
                </div>
                <div >
                  <p className="block lightYellowText font-medium text-sm ">Trade License</p>
                  <Field type="text" name="trade_license" placeholder='Enter License No.' className="w-full inputBoxBorder inputBoxBg rounded-md font-normal text-sm py-2 px-3 mb-1" />
                  <div className="errorMsg"><p className="m-0">{errors?.trade_license && touched?.trade_license ? errors?.trade_license : null}</p></div>
                </div>

                <div >
                  <p className="block lightYellowText font-medium text-sm ">Password</p>
                  <Field name="password">
                    {({ field }) => (
                      <Input.Password
                        {...field}
                        className="inputBoxBorder rounded-md inputBoxBg text-white w-full py-2 px-5 mb-1"
                        placeholder='Enter Password'
                      />
                    )}
                  </Field>
                  {/* <Field type="password" name="password" placeholder='Enter Password' className="w-full fieldBg border borderStyle rounded-md font-normal text-sm py-2 px-3" /> */}
                  <div className="errorMsg"><p className="m-0">{errors?.password && touched?.password ? errors?.password : null}</p></div>
                </div>
              </div>
              <div className='border-b borderStyle w-full darkYellowText pb-3 text-xl font-bold'>KYC Details</div>
              <div className='grid grid-cols-2 mt-4'>
                <div className=" mx-3">
                  <p className="block lightYellowText font-medium text-sm ">Select Document Type</p>
                  <Select
                    name='document_type'
                    options={[
                      { value: 'national_id', label: 'National ID' },
                      { value: 'driving_license', label: 'Driving License' },
                      { value: 'passport', label: 'Passport' }
                    ]}
                    onChange={value => setDocumentType(value)}
                    value={documentType || 'national_id'} // Ensure
                    className='h-10 w-full mb-1'
                  />
                </div>
                <div className=" mx-3">
                  <p className="block lightYellowText font-medium text-sm ">Document Number</p>
                  <Field type="text" name="document_number" placeholder='Enter document number' className="w-full inputBoxBorder inputBoxBg rounded-md font-normal text-sm py-2 px-3 mb-1" />
                  <div className="errorMsg"><p className="m-0">{errors?.document_number && touched?.document_number ? errors?.document_number : null}</p></div>
                </div>
                <div className='col-span-2 mx-3 mt-5'>
                  <Dragger
                    onChange={handleUpload}
                    key={draggerKey} maxCount={1}
                    name='document_img'
                    beforeUpload={() => false}
                    onRemove={() => {
                      setImageFile(null);
                      setImagePreview(null);
                    }}
                  >
                    <p>
                      <InboxOutlined />
                    </p>
                    <p >Click or drag file to this area to upload</p>
                  </Dragger>
                  {imagePreview && (
                    <div className="mt-2 flex justify-end">
                      <Image src={imagePreview} alt="Preview" height='50px' width='50px' className='object-contain' />
                    </div>
                  )}
                </div>
              </div>
              <div className='mt-3 mx-3'>
                <button type="submit" className="w-full btnGradient rounded-md font-normal text-sm py-2 hover:darkYellowText hover:duration-500">Save</button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalComponent>
      <div className="relative overflow-x-auto shadow-md darkBgrounded-lg border-2 borderStyle rounded-md mt-3">
        <Table
          dataSource={searchUserList}
          columns={clientManager_column(currentPage)}
          pagination={false}
        />
      </div>
      <div className='text-right mt-5'>
        <Pagination current={currentPage} total={totalPages} onChange={handlePageChange} />
      </div>
    </>
  )
}
