import moment from "moment/moment";
import { Link } from "react-router-dom";

const getRowNumber = (currentPage, index) => {
    return (currentPage - 1) * 10 + index + 1;
};

const delivery_columns = (currentPage) => [
    {
        title: 'No.',
        dataIndex: 'no',
        render: (text, record, index) => getRowNumber(currentPage, index),
    },
    {
        title: 'Name',
        dataIndex: 'clients',
        key: 'first_name',
        sorter: (a, b) => a.clients?.first_name?.localeCompare(b.clients?.first_name),
        render: (text, record) => (<Link to={`/clientdetail/${record?.client_id}`} className="darkYellowText hover:text-[#f6da6d]">{text?.first_name} {text?.last_name}</Link>)
    },
    {
        title: 'Delivery Agency',
        dataIndex: 'delivery_agency',
        key: 'delivery_agency',
        sorter: (a, b) => a.delivery_agency?.agency_name?.localeCompare(b.delivery_agency?.agency_name),
        render: (text, record) => (<>{text?.agency_name} </>)
    },
    {
        title: 'Trading',
        dataIndex: 'total_gold',
        key: 'total_gold',
        sorter: (a, b) => a.total_gold?.localeCompare(b.total_gold),
    },
    {
        title: 'Request date',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: (a, b) => moment(a.created_at) - moment(b.created_at),
        render: (text) => <div>{moment(text).format('lll')}</div>
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text) => {
            let color, backgroundColor;
            if (text === 'Pending' || text === 'pending') {
                color = '#F0A11B';
                backgroundColor = '#f0a11b3b';
            }
            else if (text === 'Completed' || text === 'completed') {
                color = '#009900';
                backgroundColor = '#00800040';
            }
            else if (text === 'Reject' || text === 'reject') {
                color = '#E20001';
                backgroundColor = '#e2000140';
            }
            return (
                <div style={{ color, backgroundColor, borderRadius: '4px' }}>{text.toUpperCase()}</div>
            )
        }
    },
    {
        title: 'Complet Date',
        dataIndex: 'completed_date',
        key: 'completed_date',
        sorter: (a, b) => moment(a.completed_date) - moment(b.completed_date),
        render: (text) => <div>{moment(text).format('lll')}</div>
    },
    {
        title: 'UpDated At',
        dataIndex: 'updated_at',
        key: 'updated_at',
        sorter: (a, b) => moment(a.updated_at) - moment(b.updated_at),
        render: (text) => <div>{moment(text).format('lll')}</div>
    },
    // {
    //     title: 'More',
    //     key: 'more',
    //     render: () => <img src='/Assets/eye_icon.png' alt="View" className="mx-auto" />
    // }
]
export default delivery_columns;
