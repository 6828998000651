import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import ApiClient from '../Services/ApiServices/ApiClinet';
import { toast } from 'react-toastify';
import { GoogleLogin } from '@react-oauth/google';
import SendOTP from '../Components/SendOTP';
import loader from '../Services/Loader/Loader.jsx';
import { useDispatch } from 'react-redux';
import { setIsLoggedIn, setUser } from '../store/userSlice.js';
import { Input } from 'antd';

export default function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [isOtp, setIsOtp] = useState(false);
    const [emailValue, setEmailValue] = useState('');

    const initialValues = {
        email: '',
        password: '',
    };

    const LoginFormSchema = Yup.object().shape({
        email: Yup.string()
            .trim()
            .email("Please enter valid email address.")
            .required("Please enter your email address.")
            .test('is-valid-email', 'Please enter valid email address.',
                value => value && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
            ),
        password: Yup.string()
            .trim()
            // .min(8, "Password must be at least 8 characters")
            // .matches(/[a-z]/, "Enter at least one lowercase letter")
            // .matches(/[A-Z]/, "Enter at least one uppercase letter")
            // .matches(/[0-9]/, "Enter at least one number")
            // .matches(/[@$!%*?&#]/, "Enter at least one special character")
            .required("Please enter your password"),
    });

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword)
    }

    useEffect(() => {
    }, [emailValue]);

    const handleSubmit = (values, { resetForm }) => {
        loader(true);
        let params = {
            ...values,
            login_type: 'api'
        }
        setEmailValue(values.email);
        ApiClient.post('merchantlogin', params).then((response) => {
            if (response?.status) {
                toast.success(response?.message);
                resetForm();
                setIsOtp(true);
                // dispatch(setIsLoggedIn(true));
                // dispatch(setUser(response?.data));
                // localStorage.setItem('token', response?.token);
                // navigate('/home');
                localStorage.setItem('email', values.email);
            }
            else {
                toast.error(response?.message);
            }
            loader(false);
        }).catch(error => {
            toast.error(error.message);
            loader(false);
        });
    }
    useEffect(() => { document.title = 'Apex Bullion-Login' }, []);
    return (
        <div className="mx-auto h-[100vh]">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 h-full">
                <div className='bgImage h-full flex justify-center items-center '>
                    <img src="/Assets/loginpic.png" alt="" className='max-w-full p-5' />
                </div>
                {isOtp ? (<SendOTP email={emailValue} />) : (
                    <Formik
                        initialValues={initialValues}
                        validationSchema={LoginFormSchema}
                        onSubmit={(values, { resetForm }) => {
                            handleSubmit(values, { resetForm });
                        }}
                    >
                        {({ errors, touched }) => (
                            <div className='frontImage h-full flex justify-center items-center px-5 py-3'>

                                <Form autoComplete="off">
                                    <div className='flex flex-col items-center '>
                                        <h3 className='font-semibold text-5xl text-t'>
                                            <span className='gradientText bg-clip-text'>Welcome back!</span>
                                        </h3>

                                        <p className=' gradientText bg-clip-text text-sm font-normal mt-3 text-center'>
                                            Simplify your workflow and boost your productivity <br />with Apex Bullion.Get started for free.
                                        </p>
                                        <div className='w-full mb-2 relative'>
                                            <Field
                                                type="email"
                                                name="email"
                                                className="inputBoxBorder rounded-md inputBoxBg  w-full mt-10  text-white py-2 px-5"
                                                placeholder="Enter Email"
                                            />
                                            <div className="errorMsg">
                                                <p className="m-0">{errors.email && touched.email ? errors.email : null}</p>
                                            </div>
                                        </div>
                                        <div className="relative w-full">
                                            <Field name="password">
                                                {({ field }) => (
                                                    <Input.Password
                                                        {...field}
                                                        className="inputBoxBorder rounded-md inputBoxBg w-full text-white py-2 px-5"
                                                        placeholder='Enter Password'
                                                    />
                                                )}
                                            </Field>
                                            {/* <Field
                                                type={showPassword ? 'text' : 'password'}
                                                name="password"
                                                className="inputBoxBorder rounded-md inputBoxBg w-full  text-white py-2 px-5"
                                                placeholder="Enter Password"
                                            /> */}
                                            {/* <img src={`/Assets/${showPassword ? 'eye' : 'View_hide'}.png`} alt=""
                                                onClick={togglePasswordVisibility}
                                                className='absolute top-[40%] right-3 cursor-pointer'
                                                style={{ transform: "translatey(-50%)" }} /> */}
                                            <p className='gradientText absolute right-0 mt-1 text-sm'><Link to='/forgotpassword'>Forgot Password ?</Link></p>
                                            <div className="errorMsg">
                                                <p className="m-0">{errors.password && touched.password ? errors.password : null}</p>
                                            </div>
                                        </div>
                                        <div className='w-full'>
                                            <button type='submit' className='btnGradient rounded-md w-full mt-10  text-[white] font-normal text-sm py-2 '>Login</button>
                                            <p className='gradientText text-sm mt-2 cursor-pointer'><Link to='/signup'>Don’t have an account? Sign up </Link></p>
                                            <div className='flex flex-row items-center justify-center  mt-4'>
                                                <div className='border-t borderStyle w-full'></div>
                                                <div className='lightYellowText w-full text-sm text-center'>or continue with</div>
                                                <div className='border-t  borderStyle w-full'></div>
                                            </div>

                                            <button type='button' className='loginBtn inputBoxBorder rounded-md w-full mt-3 text-[white] font-normal text-sm py-2 flex items-center justify-center' >
                                                <img src="/Assets/google.png" alt="" className='me-3' />
                                                Authorize with Google
                                            </button>
                                            {/* <GoogleLogin onSuccess={credentialResponse => {
                                                console.log(credentialResponse);
                                            }}
                                                onError={() => {
                                                    console.log('Login Failed');
                                                }}>

                                            </GoogleLogin> */}
                                            <button type='button' className='loginBtn inputBoxBorder rounded-md w-full mt-2  text-[white] font-normal text-sm py-2 pl-3 flex items-center justify-center'>
                                                <img src="/Assets/fb.png" alt="" className='me-3' />
                                                Authorize with Facebook
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        )}
                    </Formik>
                )}
            </div>
        </div>
    )
}


