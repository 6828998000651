import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function BackButton() {
    const goBack = () => {
        window.history.go(-1);
      };
    return (
        <>
            <button className='me-2' onClick={goBack}>
                <img src="/Assets/arrow-back.svg" alt="" className='h-6  hover:bg-[#ba8c2d] rounded-full'/>
            </button >
        </>
    )
}
