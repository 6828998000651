
import moment from "moment";
import { Image } from "antd";
import { Link } from "react-router-dom";

const getRowNumber = (currentPage, index) => {
    return (currentPage - 1) * 10 + index + 1;
};

const clientReportColumns = (currentPage) =>[
    {
        title: 'No.',
        dataIndex: 'no',
        render: (text, record, index) => getRowNumber(currentPage, index),
    },
    {
        title: 'Name',
        dataIndex: 'first_name',
        key: 'name',
        sorter: (a, b) => a.first_name?.localeCompare(b.first_name),
        render: (text, record) => (<>{record?.first_name} {record?.last_name}</>)
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        sorter: (a, b) => a.email?.localeCompare(b.email),

    },
    {
        title: 'Mobile No.',
        dataIndex: 'mobile_no',
        key: 'mobile_no',
        sorter: (a, b) => a.mobile_no?.localeCompare(b.mobile_no),
    },
    {
        title: 'Profile Image',
        dataIndex: 'profile_image',
        key: 'profile_image',
        render: (text, record) => (
            <Image src={text} alt="Document" style={{ borderRadius: '50%', width: '30px', height: '30px' }} />
        ),
    },
    {
        title: 'Country',
        dataIndex: 'country',
        key: 'country',
        sorter: (a, b) => a.country?.localeCompare(b.country),
    },
    {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        sorter: (a, b) => a.state?.localeCompare(b.state),
    },
    {
        title: 'City',
        dataIndex: 'city',
        key: 'city',
        sorter: (a, b) => a.city?.localeCompare(b.city),
    },
    {
        title: 'Kyc Status',
        dataIndex: 'kyc_status',
        key: 'kyc_status',
        sorter: (a, b) => a.kyc_status?.localeCompare(b.kyc_status),
        
        render: (text) => {
            let color = '';
            let backgroundColor = '';
            let upperCaseText = text.toUpperCase();

            if (text === 'pending' || text === 'Pending') {
                color = '#F0A11B';
                backgroundColor = '#f0a11b3b';
            } else if (text === 'completed' || text === 'Completed') {
                color = '#009900';
                backgroundColor = '#00800040';
            } else if (text === 'reject' || text === 'Reject') {
                color = '#E20001';
                backgroundColor = '#e2000140';
            }
            return (
                <div style={{ color, backgroundColor, borderRadius: '4px' }}>
                    {upperCaseText}
                </div>
            );
        }
    },
    {
        title: 'FTD Status',
        dataIndex: 'ftd_status',
        key: 'ftd_status',
        sorter: (a, b) => a.ftd_status?.localeCompare(b.ftd_status),
    },
    {
        title: 'Total Gold',
        dataIndex: 'total_gold',
        key: 'total_gold',
        sorter: (a, b) => a.total_gold?.localeCompare(b.total_gold),
    },
    {
        title: 'Wallet Balance',
        dataIndex: 'wallet_balance',
        key: 'wallet_balance',
        sorter: (a, b) => a.wallet_balance?.localeCompare(b.wallet_balance),
    },
    {
        title: 'Updated At',
        dataIndex: 'updated_at ',
        key: 'updated_at',
        sorter: (a, b) => moment(a.updated_at) - moment(b.updated_at),
        render: (text) => <div>{moment(text).format('lll')}</div>
    },
]

export default clientReportColumns;



















