import React, { useEffect, useState } from 'react';
import Navbar from '../Components/Navbar';
import { Pagination, Radio, Table } from 'antd';
import { useParams } from 'react-router-dom';
import ApiClient from '../Services/ApiServices/ApiClinet';
import { toast } from 'react-toastify';
import loader from '../Services/Loader/Loader';
import BackButton from '../Components/BackButton';
import ModalComponent from '../Components/Modal';
import deposit_columns from '../Columns/DepositManager/deposit';
import support from '../Columns/Support/support';
import delivery_columns from '../Columns/DeliveryManager/delivery';
import withdraw_columns from '../Columns/WithdrawManager/withdraw';
import kycList_columns from '../Columns/KycList/kycList';

const tabData = [
  { name: "deposit", url: "changedepositestatus" },
  { name: "withdraw", url: "changewithdrawstatus" },
  { name: "kyc", url: "changekycstatus" },
]

export default function ClientDetail() {

  const [clientDetail, setClientDetail] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [catagory, setCatagory] = useState('deposit');
  const [pendingData, setPendingData] = useState([]);
  const [isAcceptOpen, setIsAcceptOpen] = useState(false);
  const [isRejectOpen, setIsRejectOpen] = useState(false);
  const [clientId, setClientId] = useState(null);
  const [statusId, setStustId] = useState(null);
  const [isBankDetailOpen, setIsBankDetailOpen] = useState(false);
  const [bankDetails, setBankDetails] = useState(null);


  const { id } = useParams();

  const getClientDetail = () => {
    loader(true);
    ApiClient.post('clientdetail', { client_id: id })
      .then((response) => {
        if (response.status) {
          setClientDetail(response?.data);
        }
        else {
          toast.error(response.message);
        }
        loader(false);
      })
      .catch(err => {
        toast.error(err.message)
        loader(false);
      });
  }

  const getPendigData = () => {
    let endPoint = '';
    let statusKey = '';
    let statusValue = '';
    switch (catagory) {
      case 'deposit':
        endPoint = 'depositelist';
        statusKey = 'status';
        statusValue = 'pending';
        break;
      case 'withdraw':
        endPoint = 'withdrawlist';
        statusKey = 'status';
        statusValue = 'pending';
        break;
      case 'delivery':
        endPoint = 'deliverylist';
        statusKey = 'status';
        statusValue = 'pending';
        break;
      case 'kyc':
        endPoint = 'kyclist';
        statusKey = 'kyc_status';
        statusValue = 'pending';
        break;
      case 'support':
        endPoint = 'supportlist';
        statusKey = 'status';
        statusValue = 'close';
        break;
      default:
        endPoint = 'depositelist';
        statusKey = 'status';
        statusValue = 'pending';
        break;
    }
    loader(true);
    ApiClient.post(endPoint, { [statusKey]: statusValue, client_id: id })
      .then((response) => {
        if (response) {
          setPendingData(response?.data);
        }
        else {
          toast.error(response?.message);
        }
        loader(false);
      })
      .catch(error => {
        toast.error(error.message);
        loader(false);
      })
  }
  const updateStatus = (params) => {
    let updateUrl;
    tabData.map(item => {
      if (catagory == item.name) {
        updateUrl = item.url
      }
    })
    ApiClient.post(updateUrl, params)
      .then((response) => {
        if (response?.status) {
          getPendigData();
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
        setIsAcceptOpen(false);
        setIsRejectOpen(false);
      })
      .catch(error => {
        toast.error(error.message);
        loader(false);
      });
  };

  const handleAcceptData = (client_id, id) => {
    setClientId(client_id);
    setStustId(id);
    setIsAcceptOpen(true);
  }
  const handleAccept = () => {
    const params = {
      client_id: clientId,
      id: statusId,
      status: 'completed'
    };
    updateStatus(params);
  };
  const handleRejectData = (client_id, id) => {
    setClientId(client_id);
    setStustId(id);
    setIsRejectOpen(true);
  }
  const handleReject = () => {
    const params = {
      client_id: clientId,
      id: statusId,
      status: 'reject'
    };
    updateStatus(params);
  };
  
  const handleBankDetail = (bank_details) => {
    setBankDetails(bank_details);
    setIsBankDetailOpen(true);
  }

  useEffect(() => {
    getClientDetail()
    document.title = 'Apex Bullion-Client Detail'
  }, []);

  useEffect(() => {
    getPendigData();
  }, [catagory]);

  return (
    <>
      <Navbar currentPage='Client Detail' />
      <div className='darkBg border borderStyle rounded-md mt-4'>

        <div className='font-semibold border-dotted border-b borderStyle rounded-t-md px-5 py-5 flex'><BackButton />Client Wallet</div>
        <div className='grid lg:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-6 rounded-md text-sm px-5 py-5'>
          <div>
            <label className='mb-1' htmlFor="walletbalance">wallet Balance</label>
            <p className='text-sm lightYellowText'>{Number(clientDetail.client_wallets?.wallet_balance).toFixed(4)}</p>
          </div>
          <div>
            <label className='mb-1' htmlFor="totalgold">Gold Portfolio</label>
            <p className='text-sm lightYellowText'>{Number(clientDetail.client_wallets?.total_gold).toFixed(4)}</p>
          </div>
          <div>
            <label className='mb-1' htmlFor="totalsellgold">Total Gold Sell</label>
            <p className='text-sm lightYellowText'>{Number(clientDetail.client_wallets?.total_sell_gold).toFixed(4)}</p>
          </div>
          <div>
            <label className='mb-1' htmlFor="totalsellgold">Total Gold Buy</label>
            <p className='text-sm lightYellowText'>{Number(clientDetail.client_wallets?.total_buy_gold).toFixed(4)}</p>
          </div>
        </div>
      </div>
      <div className='darkBg border borderStyle  rounded-md mt-4'>
        <div className='font-semibold border-dotted border-b borderStyle rounded-t-md px-5 py-5'>Personal Details</div>
        <div className='grid lg:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-6 rounded-md text-sm px-5 py-5 '>

          <div>
            <label className='mb-1' htmlFor="firstname" >First Name</label>
            <p className='text-sm lightYellowText'>{clientDetail.first_name}</p>
          </div>
          <div>
            <label className='mb-1' htmlFor="lastname" >Last Name</label>
            <p className='text-sm lightYellowText'>{clientDetail.last_name}</p>
          </div>
          <div>
            <label className='mb-1' htmlFor="email">Email</label>
            <p className='text-sm lightYellowText'>{clientDetail.email}</p>
          </div>
          <div>
            <label className='mb-1' htmlFor="mobile">Mobile</label>
            <p className='text-sm lightYellowText'>{clientDetail.mobile_no}</p>
          </div>
          <div>
            <label className='mb-1' htmlFor="joiningdate">Joining Date</label>
            <p className='text-sm lightYellowText'>{clientDetail.joining_date}</p>
          </div>
          <div>
            <label className='mb-1' htmlFor="address">Address</label>
            <p className='text-sm lightYellowText'>{clientDetail.address}</p>
          </div>
          <div>
            <label className='mb-1' htmlFor="ftdstatus">FTD Status</label>
            <p className='text-sm lightYellowText'>{clientDetail.ftd_status}</p>
          </div>
          <div>
            <label className='mb-1' htmlFor="clientcode">Client Code</label>
            <p className='text-sm lightYellowText'>{clientDetail.client_code}</p>
          </div>
        </div>
      </div>
      <div className='darkBg border borderStyle  rounded-md mt-4 '>
        <div className='border-dotted border-b borderStyle font-semibold rounded-t-md px-5 py-5'>KYC Details</div>
        <div className='grid lg:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-6 rounded-md text-sm px-5 py-5 '>
          <div>
            <label className='mb-1' htmlFor="status">Status</label>
            <p className='text-sm lightYellowText'>{clientDetail.kyc?.status}</p>
          </div>
          <div>
            <label className='mb-1' htmlFor="documenttype">Document Type</label>
            <p className='text-sm lightYellowText'>{clientDetail.kyc?.document_type}</p>
          </div>
          <div>
            <label className='mb-1' htmlFor="documentno">Document No.</label>
            <p className='text-sm lightYellowText'>{clientDetail.kyc?.document_number}</p>
          </div>
          <div>
            <button className='btnGradient rounded-md py-1 px-3 hover:scale-105 duration-500'>
              <a href={`${clientDetail.kyc?.document_img}`} target='_blank' >View Document</a>
            </button>
          </div>
        </div>
      </div>

      <div>
        <div className='flex sm:flex-row flex-col sm:justify-between mt-4'>
          <div className='font-semibold'>Pending Detail</div>
          <Radio.Group value={catagory} onChange={(e) => setCatagory(e.target.value)} buttonStyle="solid" >
            <Radio.Button className=' sm:text-[14px] text-[10px]' value="deposit">Deposit</Radio.Button>
            <Radio.Button className=' sm:text-[14px] text-[10px]' value="withdraw">Withdraw</Radio.Button>
            <Radio.Button className=' sm:text-[14px] text-[10px]' value="delivery">Delivery</Radio.Button>
            <Radio.Button className=' sm:text-[14px] text-[10px]' value="support">SupportTicket</Radio.Button>
            <Radio.Button className=' sm:text-[14px] text-[10px]' value="kyc">KYC</Radio.Button>
          </Radio.Group>
        </div>
        <div className='darkBg overflow-x-auto rounded-lg border-2 borderStyle mt-3'>
          <Table
            dataSource={pendingData}
            columns={
              catagory === 'deposit' ? deposit_columns(currentPage, handleAcceptData, handleRejectData, 'pending') :
                catagory === 'withdraw' ? withdraw_columns(currentPage, handleAcceptData, handleRejectData, 'pending', handleBankDetail) :
                  catagory === 'delivery' ? delivery_columns(currentPage) :
                    catagory === 'support' ? support(currentPage) :
                      catagory === 'kyc' ? kycList_columns(currentPage, handleAcceptData, handleRejectData, 'pending') : []}
            pagination={false} />
        </div>
        <ModalComponent isOpen={isAcceptOpen} setIsOpen={setIsAcceptOpen} title='' width='25%'>
          <div className="flex flex-col justify-center items-center">
            <img src="/Assets/Accept-icon.svg" alt="" className='h-30 p-4' />
            <h2 className='logoutText mt-4'>Are You Sure, You Want To Accept ?</h2>
            <div className="flex mt-5 w-full">
              <button className=" px-5 py-2 bg-green-500 hover:bg-green-600 rounded-md font-semibold w-full mx-3" onClick={() => handleAccept()}>Yes</button>
              <button className='px-5 py-2 bg-red-500 hover:bg-red-600 rounded-md font-semibold w-full mx-3' onClick={() => setIsAcceptOpen(false)}>No</button>
            </div>
          </div>
        </ModalComponent>
        <ModalComponent isOpen={isRejectOpen} setIsOpen={setIsRejectOpen} title='' width='25%'>
          <div className="flex flex-col justify-center items-center">
            <img src="/Assets/Reject-icon.svg" alt="" className='h-30 p-4' />
            <h2 className='logoutText mt-4'>Are You Sure, You Want To Reject ?</h2>
            <div className="flex mt-5 w-full">
              <button className=" px-5 py-2 bg-green-500 hover:bg-green-600 rounded-md font-semibold w-full  mx-3" onClick={() => handleReject()}>Yes</button>
              <button className='px-5 py-2 bg-red-500 hover:bg-red-600 rounded-md font-semibold w-full mx-3 ' onClick={() => setIsRejectOpen(false)}>No</button>
            </div>
          </div>
        </ModalComponent>
        <ModalComponent isOpen={isBankDetailOpen} setIsOpen={setIsBankDetailOpen} title='Bank Details' width='25%'>
          {bankDetails && (
            <div className='grid grid-cols-2 mt-5 gap-4'>
              <div>
                <p className='font-semibold'>Bank Holder Name:</p>
                <p>{bankDetails.bank_holder_name}</p>
              </div>
              <div>
                <p className='font-semibold'>Bank Name:</p>
                <p>{bankDetails.bank_name}</p>
              </div>
              <div>
                <p className='font-semibold'> Bank Account No:</p>
                <p>{bankDetails.bank_account_no}</p>
              </div>
              <div>
                <p className='font-semibold'>Bank IFSC Code:</p>
                <p>{bankDetails.bank_ifsc_code}</p>
              </div>
              <div>
                <p className='font-semibold'>Bank Swift No:</p>
                <p> {bankDetails.bank_swift_no}</p>
              </div>
            </div>
          )}
        </ModalComponent>
        {/* <div className='text-right mt-5'>
          <Pagination current={currentPage} total={totalPages} onChange={page=>setCurrentPage(page)} />
        </div> */}
      </div>
    </>
  )
}
