import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ApiClient from '../Services/ApiServices/ApiClinet';
import { setIsLoggedIn } from '../store/userSlice';
import { toast } from 'react-toastify';
import ModalComponent from './Modal';
import loader from '../Services/Loader/Loader.jsx';

export default function Logout({ isOpen, setIsOpen }) {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogout = () => {
        loader(true);
        ApiClient.post('merchantlogout')
            .then((response) => {
                if (response?.status) {
                    dispatch(setIsLoggedIn(false));
                    navigate('/login');
                    setIsOpen(false);
                    localStorage.clear();
                    toast.success(response.message);
                } else {
                    toast.error(response?.message);
                }
                loader(false);
            })
            .catch(error => {
                toast.error(error.message);
                loader(false);
            })
    }

    useEffect(() => { document.title = 'Apex Bullion-Logout' }, [isOpen]);

    return (
        <ModalComponent isOpen={isOpen} setIsOpen={setIsOpen} title='Are you sure ?' >
            <div className="flex justify-center my-4 items-center flex-col">
                <div className="logoutIconOuter bg-red-400">
                    <img src="/Assets/logout.svg" alt="logout" />
                </div>
                <h2 className='logoutText mt-4'>Are You Sure You Want To Logout ?</h2>
                <div className="flex mt-3">
                    <button className="mx-3 underline" onClick={e => setIsOpen(false)}>No, Keep Login</button>
                    <button className='px-5 py-2 bg-red-500 rounded-full font-semibold' onClick={handleLogout}>Yes, Logout</button>
                </div>
            </div>
        </ModalComponent>
    );
}
