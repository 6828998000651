import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from 'redux-persist/lib/storage';
import userSlice from "./userSlice";


const persistConfig = {
    key: 'root',
    storage,
    version: 1,
    whitelist: ["user","isLoggedIn"],
};

const persistedReducer = persistReducer(persistConfig, userSlice);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
});


export const persistor = persistStore(store);
