import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import ApiClient from '../Services/ApiServices/ApiClinet';
import { toast } from 'react-toastify';
import Navbar from '../Components/Navbar';
import loader from '../Services/Loader/Loader.jsx';
import { Input } from 'antd';


export default function Setting() {

  const [showPassword, setShowPassword] = useState(false);

  const initialValues = {
    client_code: '',
    new_password: ''
  }

  const resetPasssFormSchema = Yup.object().shape({
    client_code: Yup.string()
      .trim()
      .required('please enter client code'),
    new_password: Yup.string()
      .trim()
      .min(8, "Password must be at least 8 characters")
      .matches(/[a-z]/, "Enter at least one lowercase letter")
      .matches(/[A-Z]/, "Enter at least one uppercase letter")
      .matches(/[0-9]/, "Enter at least one number")
      .matches(/[@$!%*?&#]/, "Enter at least one special character")
      .required("Please enter your password"),

  })

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }

  const handleSubmit = (value, { resetForm }) => {
    let params = {
      ...value,
    }
    ApiClient.post('resetpassword', params)
      .then((response) => {
        if (response?.status) {
          toast.success(response?.message);
          resetForm();
        } else {
          toast.error(response?.message);
        }
        loader(false);
      })
      .catch((error) => {
        toast.error(error.message);
        loader(false);
      })
  }
  useEffect(() => { document.title = 'Apex Bullion-Setting' }, []);
  return (
    <>
      <Navbar currentPage='Setting' />
      <div className='darkBg border-2 borderStyle rounded-md mt-8 '>
        <Formik
          initialValues={initialValues}
          validationSchema={resetPasssFormSchema}
          onSubmit={(value, { resetForm }) => {
            handleSubmit(value, { resetForm });
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <p className='headingBG font-semibold text-base py-2 px-3 rounded-t-md'>Change User Password</p>
              <div className='flex md:flex-row flex-col gap-5 p-5 md:items-center'>
                <div className="md:w-1/3 ">
                  <label htmlFor="client_code" className="block lightYellowText font-medium text-sm mb-1">Client Code :</label>
                  <Field
                    type="text"
                    name='client_code'
                    placeholder='Enter Client Code'
                    className="w-full inputBoxBorder inputBoxBg rounded-md font-normal  text-sm py-2 px-3 mb-1"
                  />
                  <div className="errorMsg">
                    <p className="m-0">{errors.client_code && touched.client_code ? errors.client_code : null}</p>
                  </div>
                </div>
                <div className="md:w-1/3 relative">
                  <label htmlFor="new_password" className="block lightYellowText font-medium text-sm mb-1">New Password :</label>
                  <Field name="new_password">
                    {({ field }) => (
                      <Input.Password
                        {...field}
                        className="inputBoxBorder rounded-md inputBoxBg text-white w-full py-2 px-5 mb-1"
                        placeholder='Enter New Password'
                      />
                    )}
                  </Field>
                  {/* <Field
                    type={showPassword ? 'text' : 'password'}
                    name='new_password'
                    placeholder='sEnter New Pasword'
                    className="w-full fieldBg border borderStyle rounded-md font-normal text-sm py-2 px-3"
                  />
                  <img src={`/Assets/${showPassword ? 'eye' : 'View_hide'}.png`}
                    alt=""
                    onClick={togglePasswordVisibility}
                    className='absolute top-[60%] right-3 cursor-pointer '
                    style={{ transform: "translateY(-60%)" }}
                  /> */}
                  <div className="errorMsg">
                    <p className="m-0">{errors.new_password && touched.new_password ? errors.new_password : null}</p>
                  </div>
                </div>
                <div className='text-center mt-2'>
                  <button type='submit' className='btnGradient font-medium text-sm px-3 py-2 rounded-md hover:scale-105 duration-500'>Change Password</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <p className=' headingBG font-semibold text-base py-2 px-3 mt-4'>Charges On Transaction</p>

        <div className='flex md:flex-row flex-col gap-5 p-5'>

          <div className="md:w-1/3 ">
            <label htmlFor="name" className="block lightYellowText font-medium text-sm mb-1">Enable:</label>
            <div className=' inputBoxBorder inputBoxBg rounded-md flex justify-around py-1.5'>
              <label htmlFor='Enable' className='flex items-center gap-1'>
                <input type="checkbox" className='checkBox' />
                Enable
              </label>
              <span className='border-r border-dotted border-[#71717A]'></span>
              <label htmlFor='Flat Rate' className='flex items-center gap-1'>
                <input type="checkbox" className='checkBox' />
                Flat Rate
              </label>
            </div>
          </div>

          <div className="md:w-1/3">
            <label htmlFor="name" className="block lightYellowText font-medium text-sm mb-1">Charges(In USD):</label>
            <input type="text" id="Charges" placeholder='0.000' className="w-full inputBoxBorder inputBoxBg rounded-md font-normal text-sm py-2 px-3" />
          </div>
        </div>
      </div>
    </>
  )
}

