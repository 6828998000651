import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import MainLayout from './Mainlayout';

import SignUp from './Pages/SignUp';
import Login from './Pages/Login';
import ForgotPassword from './Pages/ForgotPassword';
import ChangePassword from './Pages/ChangePassword';
import Home from './Pages/Home';
import ClientManager from './Pages/ClientManager';
import GoldPortfolio from './Pages/GoldPortfolio';
import KycList from './Pages/KycList';
import DeliveryManage from './Pages/DeliveryManage';
import DepositManager from './Pages/DepositManager';
import WithdrawManager from './Pages/WithdrawManager';
import ReportManager from './Pages/ReportManager';
import KycReport from './Pages/KycReport';
import ClientReport from './Pages/ClientReport';
import DeliverReport from './Pages/DeliverReport';
import DepositReport from './Pages/DepositReport';
import WithdrawReport from './Pages/WithdrawReport';
import News from './Pages/News';
import Support from './Pages/Support';
import SupportTicket from './Pages/SupportTicket';
import Notification from './Pages/Notification';
import Setting from './Pages/Setting';
import ClientDetail from './Pages/ClientDetail';
import BuySellManager from './Pages/BuySellManager';
import PageNotFound from './Pages/PageNotFound';

function App() {
  return (
    <>
      <Router>
        <ToastContainer />
        <Routes>
          <Route path='*' element={<PageNotFound />} />
          <Route path='/login' element={<AuthRouter><Login /></AuthRouter>} />
          <Route path='/signup' element={<AuthRouter><SignUp /></AuthRouter>} />
          <Route path='/forgotpassword' element={<AuthRouter><ForgotPassword /></AuthRouter>} />
          <Route path='/forgotpassword/:token' element={<AuthRouter><ChangePassword /></AuthRouter>} />
          <Route path='/' element={<Navigate to={'/home'} />} />
          <Route path='/home' element={<ProtectedRouter ><Home /> </ProtectedRouter>} />
          <Route path='/clientmanager' element={<ProtectedRouter><ClientManager /> </ProtectedRouter>} />
          <Route path='/goldportfolio' element={<ProtectedRouter><GoldPortfolio /> </ProtectedRouter>} />
          <Route path='/kyclist' element={<ProtectedRouter><KycList /> </ProtectedRouter>} />
          <Route path='/deliverymanager' element={<ProtectedRouter><DeliveryManage /> </ProtectedRouter>} />
          <Route path='/depositmanager' element={<ProtectedRouter><DepositManager /> </ProtectedRouter>} />
          <Route path='/withdrawmanager' element={<ProtectedRouter><WithdrawManager /> </ProtectedRouter>} />
          <Route path='/buysellmanager' element={<ProtectedRouter><BuySellManager /> </ProtectedRouter>} />
          <Route path='/reportmanager' element={<ProtectedRouter><ReportManager /> </ProtectedRouter>} />
          <Route path='/kycreport' element={<ProtectedRouter><KycReport /> </ProtectedRouter>} />
          <Route path='/clientreport' element={<ProtectedRouter><ClientReport /> </ProtectedRouter>} />
          <Route path='/deliveryreport' element={<ProtectedRouter><DeliverReport /> </ProtectedRouter>} />
          <Route path='/depositreport' element={<ProtectedRouter><DepositReport /> </ProtectedRouter>} />
          <Route path='/withdrawreport' element={<ProtectedRouter><WithdrawReport /> </ProtectedRouter>} />
          <Route path='/news' element={<ProtectedRouter><News /> </ProtectedRouter>} />
          <Route path='/support' element={<ProtectedRouter><Support /> </ProtectedRouter>} />
          <Route path='/support/:id' element={<ProtectedRouter><SupportTicket /> </ProtectedRouter>} />
          <Route path='/notification' element={<ProtectedRouter><Notification /> </ProtectedRouter>} />
          <Route path='/setting' element={<ProtectedRouter><Setting /> </ProtectedRouter>} />
          <Route path='/clientdetail/:id' element={<ProtectedRouter><ClientDetail /> </ProtectedRouter>} />
        </Routes>
      </Router>
      <div id="loader" className="loaderDiv d-none">
        <div className='loader'></div>
        <p className='mb-0 mt-2 text-xl text-[#FCD84E]'>Loading...</p>
      </div>
    </>
  );
}

export const ProtectedRouter = ({ children }) => {

  const [canGo, setCanGo] = useState('loading');
  const { isLoggedIn } = useSelector(state => state)

  useEffect(() => {
    if (isLoggedIn) {
      setCanGo('loggedin')
    }
    else {
      setCanGo('loggedout')
    }
  }, [isLoggedIn]);

  if (canGo === 'loggedin') {
    return (
      <MainLayout>
        {children}
      </MainLayout>
    )
  }
  if (canGo === 'loggedout') {
    return (
      <Navigate to='/login' />
    )
  }
}

export const AuthRouter = ({ children }) => {
  const [canGo, setCanGo] = useState('loading');
  const { isLoggedIn } = useSelector(state => state);
  useEffect(() => {
    if (isLoggedIn) { setCanGo('loggedin') }
    if (!isLoggedIn) { setCanGo('loggedout') }
  }, [isLoggedIn]);
  if (canGo === 'loggedin') {
    return (
      <Navigate to='/' />
    )
  }
  if (canGo === 'loggedout') {
    return (
      <>
        {children}
      </>
    )
  }
}

export default App;

