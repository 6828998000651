import moment from "moment";

const getRowNumber = (currentPage, index) => {
    return (currentPage - 1) * 10 + index + 1;
};

const withdrawReport_columns = (currentPage) =>[
    {
        title: 'No.',
        dataIndex: 'no',
        render: (text, record, index) => getRowNumber(currentPage, index),
    },
    {
        title: 'Name',
        dataIndex: 'clients',
        key: 'name',
        sorter: (a, b) => a.clients?.first_name?.localeCompare(b.clients?.first_name),
        render: (text, record) => (<>{text?.first_name} {text?.last_name}</>)
    },
    {
        title: 'Mobile',
        dataIndex: 'clients',
        key: 'mobile_no',
        sorter: (a, b) => a.clients?.mobile_no?.localeCompare(b.clients?.mobile_no),
        render: (record) => record?.mobile_no
    },
    {
        title: 'Email',
        dataIndex: 'clients',
        key: 'email',
        sorter: (a, b) => a.clients?.email?.localeCompare(b.clients?.email),
        render: (record) => record?.email
    },    
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        sorter: (a, b) => a.amount?.localeCompare(b.amount),
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        sorter: (a, b) => a.status?.localeCompare(b.status),
        render: (text) => {
            let color = '';
            let backgroundColor = '';
            let upperCaseText = text.toUpperCase();

            if (text === 'pending' || text === 'Pending') {
                color = '#F0A11B';
                backgroundColor = '#f0a11b3b';
            } else if (text === 'completed' || text === 'Completed') {
                color = '#009900';
                backgroundColor = '#00800040';
            } else if (text === 'reject' || text === 'Reject') {
                color = '#E20001';
                backgroundColor = '#e2000140';
            }
            return (
                <div style={{ color, backgroundColor, borderRadius: '4px' }}>
                    {upperCaseText}
                </div>
            );
        }
    },
    {
        title: 'Bank Details',
        dataIndex: 'bank_details',
        key: 'bank_details',
    },
    {
        title: 'Updated At',
        dataIndex: 'updated_at ',
        key: 'updated_at',
        sorter: (a, b) => moment(a.updated_at) - moment(b.updated_at),
        render: (text) => <div>{moment(text).format('lll')}</div>
    },
]
export default withdrawReport_columns;