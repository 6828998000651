
import React, { useEffect, useState } from 'react';
import Navbar from '../Components/Navbar';
import loader from '../Services/Loader/Loader';
import ApiClient from '../Services/ApiServices/ApiClinet';
import { toast } from 'react-toastify';
import { DatePicker, Pagination, Radio, Table } from 'antd';
import deposit_columns from '../Columns/DepositManager/deposit';
import ModalComponent from '../Components/Modal';

export default function DepositManager() {
  const [depositData, setDepositData] = useState([]);
  const [statusFilter, setStatusFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isAcceptOpen, setIsAcceptOpen] = useState(false);
  const [isRejectOpen, setIsRejectOpen] = useState(false);
  const [clientId, setClientId] = useState(null);
  const [depositId, setDepositId] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [search, setSearch] = useState('');

  const getDepositData = (page) => {
    loader(true);
    ApiClient.post('depositelist', { status: statusFilter, page, start_date: startDate, end_date: endDate, search })
      .then((response) => {
        if (response?.status) {
          setDepositData(response?.data);
          setTotalPages(response?.total_record);
        }
        else {
          toast.error(response?.message);
        }
        loader(false);
      })
      .catch(error => {
        toast.error(error.message);
        loader(false);
      });
  };

  const updateStatus = (params) => {
    ApiClient.post('changedepositestatus', params)
      .then((response) => {
        if (response?.status) {
          getDepositData(currentPage);
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
        setIsAcceptOpen(false);
        setIsRejectOpen(false);
      })
      .catch(error => {
        toast.error(error.message);
        loader(false);
      });
  };

  const handleAcceptData = (client_id, deposite_id) => {
    setClientId(client_id);
    setDepositId(deposite_id);
    setIsAcceptOpen(true);
  };

  const handleAccept = () => {
    const params = {
      client_id: clientId,
      id: depositId,
      status: 'completed'
    };
    updateStatus(params);
  };

  const handleRejectData = (client_id, deposite_id) => {
    setClientId(client_id);
    setDepositId(deposite_id);
    setIsRejectOpen(true);
  };

  const handleReject = () => {
    const params = {
      client_id: clientId,
      id: depositId,
      status: 'reject'
    };
    updateStatus(params);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    getDepositData(1);
  };

  useEffect(() => {
    getDepositData(currentPage);
    document.title = 'Apex Bullion-Deposit Manager';
  }, [statusFilter, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [statusFilter]);

  return (
    <>
      <Navbar currentPage='Deposit Manager' />
      <div className='flex lg:flex-row flex-col gap-2 justify-between sm:items-end mt-3'>
        <Radio.Group value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)} buttonStyle="solid" className='flex'>
          <Radio.Button className='text-nowrap text-center' value="">All</Radio.Button>
          <Radio.Button className='text-nowrap text-center' value="pending">Pending</Radio.Button>
          <Radio.Button className='text-nowrap text-center' value="completed">Completed</Radio.Button>
          <Radio.Button className='text-nowrap text-center' value="reject">Reject</Radio.Button>
        </Radio.Group>
        <form className='flex sm:flex-row flex-col sm:items-end gap-3 items-start sm:mt-0 mt-2 ' onSubmit={handleSubmit} autoComplete='off'>
          <div className='relative'>
            <input
              type="text"
              placeholder='Search...'
              className='darkBg px-6 py-1 rounded-md focus:outline-none'
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='h-4 fill-[#c0c0c071] absolute top-2 left-1'><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" /></svg>
          </div>
          <div className='flex gap-3'>
            <div className='flex flex-col'>
              <label htmlFor="sdate">Start Date</label>
              <DatePicker format={'DD-MM-YYYY'} onChange={date => setStartDate(date?.toString())} className='py-1'
                disabledDate={current => { return current && (current > new Date() || current > new Date(endDate)); }}
              />
            </div>
            <div className='flex flex-col'>
              <label htmlFor="edate">End Date</label>
              <DatePicker format={'DD-MM-YYYY'} onChange={date => setEndDate(date?.toString())} className='py-1'
                disabledDate={current => { return current && (current > new Date() || current < new Date(startDate)); }}
              />
            </div>
            <div className='mt-auto'>
              <button type="submit" className='btnGradient py-1 px-3 rounded-md hover:scale-105 duration-300'>
                Search
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="relative overflow-x-auto shadow-md darkBg rounded-lg border-2 borderStyle mt-3">
        <Table dataSource={depositData} columns={deposit_columns(currentPage, handleAcceptData, handleRejectData, statusFilter)} pagination={false} />
      </div>
      <div className='text-right mt-5'>
        <Pagination current={currentPage} total={totalPages} onChange={page => setCurrentPage(page)} />
      </div>
      <ModalComponent isOpen={isAcceptOpen} setIsOpen={setIsAcceptOpen} title='' width='25%'>
        <div className="flex flex-col justify-center items-center">
          <img src="/Assets/Accept-icon.svg" alt="" className='h-30 p-4' />
          <h2 className='logoutText mt-4'>Are You Sure, You Want To Accept?</h2>
          <div className="flex mt-5 w-full">
            <button className=" px-5 py-2 bg-green-500 hover:bg-green-600 rounded-md font-semibold w-full mx-3" onClick={() => handleAccept()}>Yes</button>
            <button className='px-5 py-2 bg-red-500 hover:bg-red-600 rounded-md font-semibold w-full mx-3' onClick={() => setIsAcceptOpen(false)}>No</button>
          </div>
        </div>
      </ModalComponent>
      <ModalComponent isOpen={isRejectOpen} setIsOpen={setIsRejectOpen} title='' width='25%'>
        <div className="flex flex-col justify-center items-center">
          <img src="/Assets/Reject-icon.svg" alt="" className='h-30 p-4' />
          <h2 className='logoutText mt-4'>Are You Sure, You Want To Reject?</h2>
          <div className="flex mt-5 w-full">
            <button className=" px-5 py-2 bg-green-500 hover:bg-green-600 rounded-md font-semibold w-full  mx-3" onClick={() => handleReject()}>Yes</button>
            <button className='px-5 py-2 bg-red-500 hover:bg-red-600 rounded-md font-semibold w-full mx-3 ' onClick={() => setIsRejectOpen(false)}>No</button>
          </div>
        </div>
      </ModalComponent>
    </>
  );
}
