import React from 'react';
import ReactApexChart from 'react-apexcharts';

export default function BarChart({deposite, withdraw}) {
    const options = {
        chart: {
            type: 'bar',
            toolbar: {
                show: false
            },
        },
        grid: {
            borderColor: "transperent"
        },
        plotOptions: {
            bar: {
                columnWidth: '70%',
                borderRadius: 4,
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 10,
            colors: ['transparent']
        },
        xaxis: {
            categories: ['1', '2', '3', '4', '5', '6', '7'],
            labels: {
                style: {
                    colors: '#FFFFFF',
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: '#FFFFFF',
                },
            },
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            style: {
                color: '#000000 !important',
                backgroundColor: "#000"
            }
        },
        colors: ['#dc2626', '#22c55e'],
        legend: {
            show: false
        },

    }
    
    const series = [
        {
            name: 'Sell',
            data: deposite
        },
        {
            name: 'Buy',
            data: withdraw
        }
    ]
    return (
        <>
            <div className='border-2 rounded-md borderStyle darkBg py-3 px-3 mt-5'>
                <div className='flex justify-end gap-3 text-sm'>
                    <p><span className='text-xl text-[#22c55e]'>●</span> Buy</p>
                    <p><span className='text-xl text-[#dc2626] '>●</span> Sell</p>
                </div>
                <div>
                    <ReactApexChart options={options} series={series} type="bar" height={430} />
                </div>
            </div>
        </>
    )
}
