import React, { useEffect, useState } from 'react'
import buySell_columns from '../Columns/BuySellManager/buysell';
import { toast } from 'react-toastify';
import loader from '../Services/Loader/Loader';
import Navbar from '../Components/Navbar';
import { DatePicker, Pagination, Table } from 'antd';
import ApiClient from '../Services/ApiServices/ApiClinet';

export default function BuySellManager() {
    const [buySellData, setBuySellData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [submit, setSubmit] = useState(false);
    const [search, setSearch] = useState('');

    const getGoldBuySell = (page) => {
        loader(true);
        ApiClient.post('goldbuyselllist', { start_date: startDate, end_date: endDate, search, page })
            .then((response) => {
                if (response.status) {
                    setBuySellData(response?.data);
                    setTotalPages(response?.total_record);
                }
                else {
                    toast.error(response.message);
                }
                loader(false);
            })
            .catch(error => {
                toast.error(error.message);
                loader(false);
            })
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        getGoldBuySell(1);
    };

    useEffect(() => {
        getGoldBuySell(currentPage);
        document.title = 'Apex Bullion-BuySell Manager'
    }, [currentPage]);

    return (
        <>
            <Navbar currentPage='BuySell Manager' />
            <form className='flex  sm:flex-row flex-col gap-2 justify-between items-end mt-5' onSubmit={handleSubmit} autoComplete='off'>
                <div className='relative'>
                    <input
                        type="text"
                        placeholder='Search...'
                        className='darkBg px-6 py-1 rounded-md focus:outline-none'
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                    />
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='h-4 fill-[#c0c0c071] absolute top-2 left-1'><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" /></svg>
                </div>
                <div className='flex gap-3'>
                    <div className='flex flex-col'>
                        <label htmlFor="sdate">Start Date</label>
                        <DatePicker format={'DD-MM-YYYY'} onChange={date => setStartDate(date)} className='py-1'
                            disabledDate={current => { return current && (current > new Date() || current > new Date(endDate)); }}
                        />
                    </div>
                    <div className='flex flex-col'>
                        <label htmlFor="edate">End Date</label>
                        <DatePicker format={'DD-MM-YYYY'} onChange={date => setEndDate(date)} className='py-1'
                            disabledDate={current => { return current && (current > new Date() || current < new Date(startDate)); }}
                        />
                    </div>
                    <div className='mt-auto'>
                        <button type="submit" className='btnGradient py-1 px-3 rounded-md hover:scale-105 duration-300'>
                            Search
                        </button>
                    </div>
                </div>
            </form>
            <div className="relative overflow-x-auto shadow-md darkBg rounded-lg border-2 borderStyle mt-3">
                <Table dataSource={buySellData} columns={buySell_columns(currentPage)} pagination={false} />
            </div>
            <div className='text-right mt-5'>
                <Pagination current={currentPage} total={totalPages} onChange={page => setCurrentPage(page)} />
            </div>
        </>
    )
}
