
import moment from "moment/moment";
import { Link } from "react-router-dom";

const getRowNumber = (currentPage, index) => {
    return (currentPage - 1) * 10 + index + 1;
};

const deposit_columns = (currentPage, handleAcceptData, handleRejectData, kycStatus) => {
    const column = [
        {
            title: 'No.',
            dataIndex: 'no',
            render: (text, record, index) => getRowNumber(currentPage, index),
        },
        {
            title: 'Name',
            dataIndex: 'clients',
            key: 'first_name',
            sorter: (a, b) => a.clients?.first_name?.localeCompare(b.clients?.first_name),
            render: (text, record) => (<Link to={`/clientdetail/${record?.client_id}`} className="darkYellowText hover:text-[#f6da6d]">{text?.first_name} {text?.last_name}</Link>)
        },
        {
            title: 'Mobile',
            dataIndex: 'clients',
            key: 'mobile_no',
            sorter: (a, b) => a.clients?.mobile_no?.localeCompare(b.clients?.mobile_no),
            render: (record) => record?.mobile_no
        },
        {
            title: 'Email',
            dataIndex: 'clients',
            key: 'email',
            sorter: (a, b) => a.clients?.email?.localeCompare(b.clients?.email),
            render: (record) => record?.email
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            sorter: (a, b) => a.amount?.localeCompare(b.amount),
        },
        {
            title: 'Transaction ID',
            dataIndex: 'transaction_hash',
            key: 'transaction_hash',
            sorter: (a, b) => a.transaction_hash?.localeCompare(b.transaction_hash),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: (a, b) => a.status?.localeCompare(b.status),
            render: (text) => {
                let color, backgroundColor;
                if (text === 'Pending' || text === 'pending') {
                    color = '#F0A11B';
                    backgroundColor = '#f0a11b3b';
                }
                else if (text === 'Completed' || text === 'completed') {
                    color = '#009900';
                    backgroundColor = '#00800040';
                }
                else if (text === 'Reject' || text === 'reject') {
                    color = '#E20001';
                    backgroundColor = '#e2000140';
                }
                return (
                    <div style={{ color, backgroundColor, borderRadius: '4px' }}>{text.toUpperCase()}</div>
                )
            }
        },
        {
            title: 'UpDated At',
            dataIndex: 'updated_at',
            key: 'updated_at',
            sorter: (a, b) => moment(a.updated_at) - moment(b.updated_at),
        },
        // {
        //     title: 'Review',
        //     key: 'review',
        //     render: () => <img src='/Assets/eye_icon.png' alt="View" className="mx-auto" />
        // }

    ];
    if (kycStatus === 'pending') {
        column.push({
            title: 'Action',
            key: 'action',
            render: (record) => (
                <div className="flex ">
                    <div className="mx-1 cursor-pointer" onClick={() => handleAcceptData(record?.client_id, record?.id)}
                    >
                        <img src="/Assets/accept_icon.svg" alt="" />
                    </div>
                    <div className="mx-1 cursor-pointer" onClick={() => handleRejectData(record?.client_id, record?.id)}
                    >
                        <img src="/Assets/delete_icon.svg" alt="" />
                    </div>
                </div>
            ),
        })
    }
    return column;
}
export default deposit_columns;
