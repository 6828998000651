
import React, { useEffect, useState } from 'react';
import Navbar from '../Components/Navbar';
import { useParams } from 'react-router-dom';
import { Carousel, Image } from 'antd';
import ApiClient from '../Services/ApiServices/ApiClinet';
import { toast } from 'react-toastify';
import moment from 'moment';
import loader from '../Services/Loader/Loader';
import ModalComponent from '../Components/Modal';
import BackButton from '../Components/BackButton';

export default function SupportTicket() {

    const [data, setData] = useState([]);
    const [msg, setMsg] = useState('');
    const [supportStatus, setSupporStatus] = useState('');
    const [title, setTitle] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const { id } = useParams();

    const handleEnterPress = (e) => {
        if (e.key === 'Enter' && msg.trim() !== '') {
            ApiClient.post('supportreplay', { message: msg, support_id: id })
                .then((response) => {
                    if (response.status) {
                        setMsg('');
                        setData(response?.data)
                    } else {
                        setMsg('');
                        toast.error(response.message);
                    }
                })
                .catch(error => {
                    toast.error(error.message);
                    loader(false);
                })
        }
    };
    const getSupportData = () => {
        loader(true);
        ApiClient.post('supportview', { support_id: id })
            .then((response) => {
                setTitle(response?.title);
                setSupporStatus(response?.support_status)
                if (response.status) {
                    setData(response?.data);
                } else {
                    toast.error(response.message);
                }
                loader(false);
            })
            .catch(error => {
                toast.error(error.message);
                loader(false);
            })
    }

    const handleClose = () => {
        ApiClient.post('supportstatuschange', { support_id: id, status: 'close' })
            .then((response) => {
                if (response.status) {
                    toast.success(response.message);
                }
                else {
                    toast.error(response.message);
                }
                setIsOpen(false);
                getSupportData();
            })
            .catch(error => {
                toast.error(error.message);
                loader(false);
            })
    }

    useEffect(() => {
        document.title = 'Apex Bullion-Support-Ticket'
        getSupportData();
    }, [])

    return (
        <>
            <Navbar currentPage='Support-Ticket' />
            <div className=' darkBg border borderStyle rounded-md mt-4 text-sm px-3 py-2 flex flex-col justify-between  h-[calc(100vh-150px)]'>
                <div className='overflow-auto'>
                    <div className='flex justify-between border-b border-[#ffffff23] p-2'>
                        <div className='flex'>
                            <BackButton pathname={'/support'} />
                            <div>
                                <img src="/Assets/dummy.png" alt="" className='rounded-full h-10 w-10 inline-block ' />
                                <span className='ms-2'>{title}</span>
                            </div>
                        </div>
                        {supportStatus === 'open' ?
                            (<button className='text cursor-pointer hover:bg-red-600 rounded-md  bg-red-500 px-5' onClick={() => setIsOpen(true)}>CLOSE</button>) :
                            (<p className='text-[#E46253] text-lg'>Support Ticket is CLOSED</p>)
                        }
                    </div>
                    <div className='mt-5 px-5'>
                        {data.map((item, index) => (
                            <div key={index}>
                                {item.replay_by === 'merchant' ? (
                                    <div className='flex flex-col items-end mb-3'>
                                        <div className='w-2/5'>
                                            <div className='flex justify-between'>
                                                <p>{item.merchant_name}</p>
                                                <p className='text-xs'>{moment(item.created_at).format('HH:mm ')}</p></div>
                                            <p className='bg-[#282828] text-xs px-3 py-2 rounded-md text-wrap'>{item.message}</p>
                                        </div>
                                    </div>
                                ) :
                                    (
                                        <div className='flex gap-2 mb-3'>
                                            <img src="/Assets/dummy.png" alt="" className='rounded-full h-10 w-10 ' />
                                            <div className='w-2/5'>
                                                <div className='flex justify-between'>
                                                    <p >{item.client_name === null ? '---' : item.client_name}</p>
                                                    <p className='text-xs'>{moment(item.created_at).format('HH:mm ')}</p>
                                                </div>
                                                <p className='bg-[#282828] text-xs px-3 py-2 rounded-md text-wrap'>{item.message}</p>
                                                <div className='flex gap-6 mt-2 '>
                                                    <Image.PreviewGroup>
                                                        {item.image.map((img_url, index_url) => (
                                                            <Image key={index_url} src={img_url} width={50} height={50} alt='' />
                                                        ))}
                                                    </Image.PreviewGroup>                                                   
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            </div>
                        ))}
                    </div>
                </div>

                <div className={`${supportStatus !== 'open' ? 'hidden' : ''} w-full relative mt-2 px-5 `}>
                    <input
                        type="text"
                        placeholder='Type a message...'
                        className='bg-[#323232] ps-5 pe-16  py-2 rounded-md focus:outline-none w-full text-white placeholder-gray-400'
                        value={msg}
                        onChange={e => setMsg(e.target.value)}
                        onKeyDown={handleEnterPress}
                    />
                    <button className='btnGradient rounded-lg absolute right-8 top-[12%] py-1 px-2' onClick={e => handleEnterPress({ key: 'Enter' })}>Send</button>
                </div>

                <ModalComponent isOpen={isOpen} setIsOpen={setIsOpen} title='' width='25%'>
                    <div className="flex flex-col justify-center items-center">
                        <img src="/Assets/Reject-icon.svg" alt="" className='h-30 p-4' />
                        <h2 className='logoutText mt-4'>Are You Sure, You Want To Close Ticket ?</h2>
                        <div className="flex mt-5 w-full">
                            <button className=" px-5 py-2 bg-green-500 hover:bg-green-600 rounded-md font-semibold w-full mx-3" onClick={() => handleClose()}>Yes</button>
                            <button className='px-5 py-2 bg-red-500 hover:bg-red-600 rounded-md font-semibold w-full mx-3 ' onClick={() => setIsOpen(false)}>No</button>
                        </div>
                    </div>
                </ModalComponent>
            </div>
        </>
    )
}
