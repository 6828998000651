import moment from "moment";
const getRowNumber = (currentPage, index) => {
    return (currentPage - 1) * 10 + index + 1;
};

const deliverReport_columns = (currentPage) =>[
    {
        title: 'No.',
        dataIndex: 'no',
        render: (text, record, index) => getRowNumber(currentPage, index),
    },
    {
        title: 'Name',
        dataIndex: 'clients',
        key: 'name',
        sorter: (a, b) => a.clients?.first_name?.localeCompare(b.clients?.first_name),
        render: (text, record) => (<>{text?.first_name} {text?.last_name}</>)
    },
    {
        title: 'Delivery Agency',
        dataIndex: 'delivery_agency',
        key: 'delivery_agency',
        sorter: (a, b) => a.delivery_agency?.agency_name?.localeCompare(b.delivery_agency?.agency_name),
        render: (text, record) => (<>{text?.agency_name} </>)
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        sorter: (a, b) => a.status?.localeCompare(b.status),
        render: (text) => {
            let color = '';
            let backgroundColor = '';
            let upperCaseText = text.toUpperCase();

            if (text === 'pending' || text === 'Pending') {
                color = '#F0A11B';
                backgroundColor = '#f0a11b3b';
            } else if (text === 'completed' || text === 'Completed') {
                color = '#009900';
                backgroundColor = '#00800040';
            } else if (text === 'reject' || text === 'Reject') {
                color = '#E20001';
                backgroundColor = '#e2000140';
            }
            return (
                <div style={{ color, backgroundColor, borderRadius: '4px' }}>
                    {upperCaseText}
                </div>
            );
        }
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        sorter: (a, b) => a.address?.localeCompare(b.address),
    },
    {
        title: 'Tracking Number',
        dataIndex: 'tracking_number',
        key: 'tracking_number',
        sorter: (a, b) => a.tracking_number?.localeCompare(b.tracking_number),
    },
    {
        title: 'Tracking Status',
        dataIndex: 'tracking_status',
        key: 'tracking_status',
        sorter: (a, b) => a.tracking_status?.localeCompare(b.tracking_status),
    },
    {
        title: 'Updated At',
        dataIndex: 'updated_at',
        key: 'updated_at',
        sorter: (a, b) => moment(a.updated_at) - moment(b.updated_at),
        render: (text) => <div>{moment(text).format('ll')}</div>
    },
]
export default deliverReport_columns;


