import { Component } from 'react';
import axios from 'axios';
import querystring from 'querystring';
import { setAuthorizationToken } from './Auth.jsx';
import { toast } from 'react-toastify';
import ApiKey from './ApiKey.jsx';

var config = {
    headers: { 'Content-Type': 'application/json' },
};

var fileConfig = {
    headers: { 'Content-Type': 'multipart/form-data' },
};

var baseUrl = ApiKey.api;

const handleError = (err, hideError) => {
    let message = ''
    if (err) {
        if (err && err.error && err.error.code == 401) {
            localStorage.clear()
            window.location.assign("/");
        }
        message = err && err.error && err.error.message
        if (!message) message = err.message
        if (!message) message = 'Server Error'
    }
    if (message) {
        if (!hideError) {
            // toast.error(message, {
            //     position: "top-right",
            // });
        }
    }
}

class ApiClient extends Component {
    constructor(props) {
        super(props);
    }

    static post(url1, params, isContainFile, base = '', hideError = '') {
        let url = baseUrl + url1
        if (base) url = base + url1

        let Config = config;
        if (isContainFile) Config = fileConfig;

        setAuthorizationToken(axios);
        return new Promise(function (fulfill, reject) {
            axios
                .post(url, params, Config)
                .then(function (response) {
                    fulfill(response && response.data);
                })
                .catch(function (error) {
                    if (error?.response?.status == 401) {
                        // toast.error('Your session has expired. Please login to pick up where you left off.');
                        localStorage.clear();
                        window.location.replace('/login');
                    }
                    if (error && error.response) {
                        let eres = error.response;
                        handleError(eres.data, hideError)
                        fulfill({ ...eres.data, success: false });
                    } else {
                        // toast.error('Network Error');
                        reject(error);
                    }
                });
        });
    }

    static put(url1, params, base = '', hidError = '', isContainFile) {
        let url = baseUrl + url1
        if (base) url = base + url1

        let Config = config;
        if (isContainFile) Config = fileConfig;

        setAuthorizationToken(axios);
        return new Promise(function (fulfill, reject) {
            axios
                .put(url, params, Config)
                .then(function (response) {
                    fulfill(response && response.data);
                })
                .catch(function (error) {
                    if (error?.response?.status == 401) {
                        // toast.error('Your session has expired. Please login to pick up where you left off.');
                        window.location.replace('/login');
                        localStorage.clear();
                    }
                    if (error && error.response) {
                        let eres = error.response;
                        handleError(eres.data, hidError)
                        fulfill(eres.data);
                    } else {
                        // toast.error('Network Error');
                        reject(error);
                    }
                });
        });
    }

    static get(url1, params = {}, base = '', hidError = '') {

        let url = baseUrl + url1
        if (base) url = base + url1

        let query = querystring.stringify(params);
        url = query ? `${url}?${query}` : url;
        setAuthorizationToken(axios);
        return new Promise(function (fulfill, reject) {
            axios
                .get(url, config)
                .then(function (response) {
                    fulfill(response && response.data);
                })
                .catch(function (error) {
                    if (error?.response?.status == 401) {
                        // toast.error('Your session has expired. Please login to pick up where you left off.');
                        window.location.replace('/login');
                        localStorage.clear();
                    }
                    if (error && error.response) {
                        let eres = error.response;
                        handleError(eres.data, hidError)
                        fulfill({ ...eres.data, success: false });
                    } else {
                        // toast.error('Network Error');
                        reject(error);
                    }
                });
        });
    }

    static delete(url1, params = {}, base = '') {
        let url = baseUrl + url1
        if (base) url = base + url1

        let query = querystring.stringify(params);
        url = query ? `${url}?${query}` : url;
        setAuthorizationToken(axios);
        return new Promise(function (fulfill, reject) {
            axios
                .delete(url, config)
                .then(function (response) {
                    fulfill(response && response.data);
                })
                .catch(function (error) {
                    if (error?.response?.status == 401) {
                        // toast.error('Your session has expired. Please login to pick up where you left off.');
                        window.location.replace('/login');
                        localStorage.clear();
                    }
                    if (error && error.response) {
                        let eres = error.response;
                        handleError(eres.data)
                        fulfill(eres.data);
                    } else {
                        // toast.error('Network Error');
                        reject(error);
                    }
                });
        });
    }

    static patch(url1, params = {}, base = '') {
        let url = baseUrl + url1
        if (base) url = base + url1

        let query = querystring.stringify(params);
        url = query ? `${url}?${query}` : url;
        setAuthorizationToken(axios);
        return new Promise(function (fulfill, reject) {
            axios
                .patch(url, config)
                .then(function (response) {
                    fulfill(response && response.data);
                })
                .catch(function (error) {
                    if (error?.response?.status == 401) {
                        // toast.error('Your session has expired. Please login to pick up where you left off.');
                        window.location.replace('/login');
                        localStorage.clear();
                    }
                    if (error && error.response) {
                        let eres = error.response;
                        handleError(eres.data)
                        fulfill(eres.data);
                    } else {
                        // toast.error('Network Error');
                        reject(error);
                    }
                });
        });
    }

    static allApi(url, params, method = 'get') {
        if (method == 'get') {
            return this.get(url, params)
        } else if (method == 'put') {
            return this.put(url, params)
        } if (method == 'post') {
            return this.post(url, params)
        }
    }

    /*************** Form-Data Method ***********/
    static postFormData(url, params) {
        url = baseUrl + url
        setAuthorizationToken(axios);
        return new Promise(function (fulfill, reject) {
            var body = new FormData();
            let oArr = Object.keys(params)
            oArr.map(itm => {
                body.append(itm, params[itm]);
            })

            axios
                .post(url, body, fileConfig)

                .then(function (response) {
                    fulfill(response && response.data);
                })
                .catch(function (error) {
                    if (error && error.response) {
                        let eres = error.response;
                        handleError(eres.data)
                        fulfill(eres.data);
                    } else {
                        // toast.error('Network Error');
                        reject(error);
                    }
                });
        });
    }

}

export default ApiClient;
