import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch } from 'react-redux';
import { setIsLoggedIn, setUser } from '../store/userSlice';
import { useNavigate } from 'react-router-dom';
import ApiClient from '../Services/ApiServices/ApiClinet';
import { toast } from 'react-toastify';
import loader from '../Services/Loader/Loader';

export default function SendOTP({ email }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [seconds, setSeconds] = useState(60);
    const [isSend, setIsSend] = useState(false)
    const initialValues = {
        otp: null,
    }
    const otpFormSchema = Yup.object().shape({
        otp: Yup.string()
            .trim()
            .required("Please enter your OTP.")
            .test('len', 'OTP must be exactly 6 digits', val => val && val.length === 6)
            .matches(/^[0-9]+$/, "OTP must contain only numbers")
    });
    const handleSubmit = (values, { resetForm }) => {
        loader(true);
        let params = {
            ...values,
            user_type: 1,
            email: email
        }
        ApiClient.post('emailverification', params)
            .then((response) => {
                if (response?.status) {
                    toast.success(response?.message);
                    resetForm();
                    dispatch(setIsLoggedIn(true));
                    dispatch(setUser(response?.data));
                    localStorage.setItem('token', response?.token);
                    navigate('/home');
                }
                else {
                    toast.error(response?.message);
                }
                loader(false);
            }).catch(error => {
                toast.error(error.message);
                loader(false);
            })
    }
    const handleResend = () => {
        const email = localStorage.getItem('email');
        ApiClient.post('resendotp', { user_type: 1, email })
            .then((response) => {
                if (response.status) {
                    toast.success(response.message);
                    setSeconds(50)
                    setIsSend(false)
                }
                else {
                    toast.error(response.message);
                }
            })
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds(prev => {
                if (prev > 0) {
                    return prev - 1
                } else {
                    clearInterval(interval)
                    return 0
                }
            });

        }, 1000);
        document.title = 'Apex Bullion-Email Verification'
        return () => {
            clearInterval(interval)
        }
    }, [isSend]);

    useEffect(() => {
        if (seconds <= 0) {
            setIsSend(true)
        } else {
            setIsSend(false)
        }
    }, [seconds])

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={otpFormSchema}
            onSubmit={(values, { resetForm }) => {
                handleSubmit(values, { resetForm });
            }}
        >
            {({ errors, touched }) => (
                <Form autoComplete="off">
                    <div className='frontImage h-full flex flex-col justify-center items-center'>
                        <h3 className='font-semibold text-3xl text-t'>
                            <span className='gradientText bg-clip-text'>Welcome</span>
                        </h3>
                        <p className=' gradientText bg-clip-text  font-normal mt-1 text-center'>
                            Please enter the OTP and verify your self
                        </p>
                        <div className='flex flex-col w-1/2 px-2 mt-10'>
                            <label htmlFor="name" className='lightYellowText text-sm font-semibold '>OTP:</label>
                            <Field
                                type="text"
                                name="otp"
                                placeholder='Enter OTP'
                                className='inputBoxBorder rounded-md inputBoxBg w-full text-white py-2 px-5 mb-1'
                                maxLength={6}  
                            />
                            <div className="errorMsg">
                                <p className="m-0">{errors.otp && touched.otp ? errors.otp : null}</p>
                            </div>
                        </div>
                        <div className='mt-10 w-1/2 px-2'>
                            <button type="submit" className='btnGradient rounded-md text-white font-normal text-xl py-1.5 w-full'>Verify</button>
                        </div>
                        <div className='flex justify-between lightYellowText w-1/2 px-3 mt-1'>
                            {
                                !isSend ?
                                    <p className='OTP Send in'>OTP Resend in {seconds} seconds</p> : <div></div>
                            }
                            {
                                isSend ?
                                    <button type='button' className=' hover:text-[#f6da6d] cursor-pointer  underline' onClick={handleResend}>Resend OTP ?</button> : ""
                            }
                        </div>
                    </div>
                </Form>)}
        </Formik>
    )
}
