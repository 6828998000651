import React from 'react'


const PageNotFound = () => {
    const text = "404 Page NotFound";
    const spacedText = text.split('').join(''); 
    return (
        <>
            <div className='flex items-center justify-center min-h-screen'>
                <div className='darkYellowText font-bold sm:text-5xl text-xl break-animation'>
                    {spacedText.split('  ').map((char, index) => (
                        <span key={index}>{char}</span>
                    ))}
                </div>
            </div>
        </>
    )
}
export default PageNotFound