import React from 'react'
import { Modal } from 'antd';

const ModalComponent = ({ isOpen = false, setIsOpen, children, title = "Gold Bullion", width = 900 }) => {

    const handleOk = () => {
        setIsOpen(false);
    };

    const handleCancel = () => {
        setIsOpen(false);
    };
    return (
        <Modal title={title} open={isOpen} onOk={handleOk} onCancel={handleCancel} className='modalarea' width={width} footer={[""]}>
            {children}
        </Modal>
    )
}

export default ModalComponent