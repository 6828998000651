
import React, { useEffect, useRef, useState } from 'react';
import Navbar from '../Components/Navbar';
import { Image, Pagination, Table } from 'antd';
import ApiClient from '../Services/ApiServices/ApiClinet';
import { toast } from 'react-toastify';
import loader from '../Services/Loader/Loader';
import notification_columns from '../Columns/Notification/notification';
import ModalComponent from '../Components/Modal';
import { Field, Formik, Form } from 'formik';
import * as Yup from "yup";
import Dragger from 'antd/es/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';

export default function Notification() {
  const [notificationData, setNotificationData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isSendOpen, setIsSendOpen] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [draggerKey, setDraggerKey] = useState(Date.now());
  const [imagePreview, setImagePreview] = useState(null);

  const formikRef = useRef();
  const initialValues = {
    title: '',
    description: '',
    'user_ids[]': 'ALL',
  };

  const notificationSchema = Yup.object().shape({
    title: Yup.string()
      .trim()
      .required("title required."),
    description: Yup.string()
      .trim()
      .required("description required."),
  });

  const getNotificationData = (page) => {
    loader(true);
    ApiClient.post('notificationlist', { page })
      .then((response) => {
        if (response?.status) {
          const newData = response?.data.map(item => {
            return {
              ...item,
              key: item.id
            }
          })
          setNotificationData(newData);
          setTotalPages(response?.total_record);
        }
        else {
          toast.error(response?.message);
        }
        loader(false);
      })
      .catch(error => {
        toast.error(error.message);
        loader(false);
      });
  }

  const handleSubmit = (values, { resetForm }) => {
    ApiClient.postFormData('notificationadd', { ...values, image: imageFile })
      .then((response) => {
        if (response) {
          resetForm();
          setIsSendOpen(false);
          getNotificationData();
          toast.success(response.message);
        }
        else {
          toast.error(response.message);
        }
      })
      .catch(error => {
        toast.error(error.message);
        loader(false);
      });
  }

  const handleRemove = () => {
    if (selectedRowKeys.length === 0) {
      toast.error('Please select at least one notification to delete.');
      setDeleteOpen(false);
      return;
    }
    ApiClient.post('notificationdelete', { ids: selectedRowKeys.toString() })
      .then((response) => {
        if (response.status) {
          toast.success(response.message);
          getNotificationData(currentPage);
          setSelectedRowKeys([]);
        }
        else {
          toast.error(response.message);
        }
        setDeleteOpen(false);
      })
      .catch(err => {
        toast.error(err.message);
        loader(false);
      });
  }

  const handleUpload = (info) => {
    const fileList = info.fileList;
    if (fileList && fileList.length > 0) {
      setImageFile(fileList[0].originFileObj);
      setImagePreview(URL.createObjectURL(fileList[0].originFileObj));
    }
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnWidth: 60
  };

  useEffect(() => {
    if (!isSendOpen && formikRef.current) {
      formikRef.current.resetForm();
      setImageFile(null);
      setImagePreview(null);
    }
    if (!isSendOpen) {
      setDraggerKey(Date.now());
    }
  }, [isSendOpen]);

  useEffect(() => {
    getNotificationData(currentPage);
    document.title = 'Apex Bullion-Notification';
  }, [currentPage]);


  return (
    <>
      <Navbar currentPage='Notification' />
      <div className='mt-5 flex justify-between'>
        <button type="button" onClick={e => setDeleteOpen(true)} className="bg-red-500 hover:bg-red-600 rounded-md flex items-center gap-2 px-2 py-1">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="20"
            height="20" fill='white'><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" /></svg>
          Delete</button>
        <button type="button" onClick={() => setIsSendOpen(true)} className="btnGradient rounded-md flex items-center gap-2 px-2 py-1">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill='white' width="20"
            height="20"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" /></svg>
          Add Notification</button>
      </div>
      <div className="overflow-x-auto shadow-md darkBg rounded-lg border-2 borderStyle mt-3">
        <Table dataSource={notificationData} columns={notification_columns(currentPage)} pagination={false} rowSelection={rowSelection} />
      </div>
      <div className='text-right mt-5'>
        <Pagination current={currentPage} total={totalPages} onChange={page => setCurrentPage(page)} />
      </div>

      <ModalComponent isOpen={isSendOpen} setIsOpen={setIsSendOpen} title='Send Notification' width='50%'>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={notificationSchema}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values, { resetForm });
          }}
        >

          {({ errors, touched }) => (
            <Form className="darkBg border-2 borderStyle rounded-md p-7 grid md:grid-cols-1 items-center gap-3" autoComplete='off' >
              <div>
                <label htmlFor="title" className="darkYellowText">Title</label>
                <Field type="text" name="title" placeholder='Title' className="w-full inputBoxBorder inputBoxBg rounded-md py-2 px-3 mb-1" />
                <div className="errorMsg">
                  <p className="m-0">{errors.title && touched.title ? errors.title : null}</p>
                </div>
              </div>
              <div>
                <label htmlFor="description" className="darkYellowText">Description</label>
                <Field as="textarea" name="description" placeholder='Description' className="w-full inputBoxBorder inputBoxBg rounded-md py-2 px-3" />
                <div className="errorMsg">
                  <p className="m-0">{errors.description && touched.description ? errors.description : null}</p>
                </div>
              </div>
              <div className='mt-2'>
                <Dragger
                  onChange={handleUpload}
                  key={draggerKey} maxCount={1}
                  beforeUpload={() => false}
                  onRemove={() => {
                    setImageFile(null);
                    setImagePreview(null);
                  }}
                >
                  <p>
                    <InboxOutlined />
                    <p >Click or drag file to this area to upload</p>
                  </p>
                </Dragger>
                {imagePreview && (
                  <div className="mt-2 flex justify-end">
                    <Image src={imagePreview} alt="Preview" height='50px' width='50px' className='object-contain' />
                  </div>
                )}
              </div>
              <div>
                <button type="submit" className='btnGradient rounded-md px-2 py-1 mt-2 hover:scale-105 duration-300'>Submit</button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalComponent>
      <ModalComponent isOpen={deleteOpen} setIsOpen={setDeleteOpen} title='' width='25%'>
        <div className="flex flex-col justify-center items-center">
          <img src="/Assets/Reject-icon.svg" alt="" className='h-30 p-4' />
          <h2 className='logoutText mt-4'>Are You Sure, You Want To Delete Notification ?</h2>
          <div className="flex mt-5 w-full">
            <button className=" px-5 py-2 bg-green-500 hover:bg-green-600 rounded-md font-semibold w-full  mx-3" onClick={handleRemove}>Yes</button>
            <button className='px-5 py-2 bg-red-500 hover:bg-red-600 rounded-md font-semibold w-full mx-3 ' onClick={e => setDeleteOpen(false)}>No</button>
          </div>
        </div>
      </ModalComponent>
    </>
  );
}
