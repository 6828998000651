import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ApiClient from '../Services/ApiServices/ApiClinet';
import loader from '../Services/Loader/Loader';

export default function ForgotPassword() {
    const navigate = useNavigate();
    const [success, setSuccess] = useState(true);
    const initialValues = {
        email: ''
    };
    const forgotPassFormSchema = Yup.object().shape({
        email: Yup.string()
            .trim()
            .email("Please enter valid email address.")
            .required("Please enter your email address.")
            .test('is-valid-email', 'Please enter valid email address.',
                value => value && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
            ),
    });

    const handleSubmit = (values, { resetForm }) => {
        loader(true);
        let params = {
            ...values
        }

        ApiClient.post('sendforgotlink', params).then((response) => {
            if (response?.status) {
                toast.success(response?.message);
                resetForm();
                setSuccess(false);
            }
            else {
                toast.error(response?.message);
            }
            loader(false);
        }).catch(error => {
            toast.error(error.message);
            loader(false);
        })
    }
    useEffect(() => { document.title = 'Apex Bullion-Forgot Password' }, []);
    return (
        <div className="mx-auto h-[100vh]">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 h-full">
                <div className='bgImage h-full flex justify-center items-center '>
                    <img src="/Assets/loginpic.png" alt="" className='max-w-full p-5' />
                </div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={forgotPassFormSchema}
                    onSubmit={(values, { resetForm }) => {
                        handleSubmit(values, { resetForm });
                    }}
                >
                    {({ errors, touched }) => (
                        <div className='frontImage h-full flex justify-center items-center py-3'>
                            {success ? (
                                <Form autoComplete="off">
                                    <div className='flex flex-col items-center '>
                                        <h3 className='font-semibold text-2xl text-t'>
                                            <span className='gradientText bg-clip-text'>ForgotPassword ?</span>
                                        </h3>
                                        <p className=' gradientText bg-clip-text text-xl font-normal mt-3 text-center'>
                                            Enter your Register Email ID
                                        </p>
                                        <div className='w-full mb-2 relative'>
                                            <Field
                                                type="email"
                                                name="email"
                                                className="inputBoxBorder rounded-md inputBoxBg  w-full mt-10  text-white py-2 px-5"
                                                placeholder="Enter Email"
                                            />
                                            <div className="errorMsg">
                                                <p className="m-0">{errors.email && touched.email ? errors.email : null}</p>
                                            </div>
                                        </div>
                                        <div className='w-full'>
                                            <button type='submit' className='btnGradient rounded-md w-full mt-1  text-white font-normal text-sm py-2'>Get Link</button>
                                            <button className='btnGradient rounded-md w-full mt-2 text-white font-normal text-sm py-2' onClick={() => navigate('/login')} >Cancel</button>
                                        </div>
                                    </div>
                                </Form>
                            ) : (
                                <div className='flex flex-col items-center '>
                                    <h3 className='font-semibold text-2xl text-t'>
                                        <span className='gradientText bg-clip-text'>Reset Password Link Sent On Your Email</span>
                                    </h3>
                                    <p className=' gradientText bg-clip-text text-xl font-normal mt-3 text-center'>
                                        Please chek your Mail
                                    </p>
                                </div>)}
                        </div>
                    )}
                </Formik>
            </div>
        </div>
    )
}
