import React, { useState, useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import ApiKey from '../Services/ApiServices/ApiKey.jsx';
import loader from '../Services/Loader/Loader.jsx';
import { Input } from 'antd';

export default function ChangePassword() {

    const navigate = useNavigate();
    const params = useParams();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const initialValues = {
        userPwd: '',
        userConfirmPwd: ''
    };

    const forgotPassFormSchema = Yup.object().shape({
        userPwd: Yup.string()
            .trim()
            .min(8, "Password must be at least 8 characters")
            .matches(/[a-z]/, "Enter at least one lowercase letter")
            .matches(/[A-Z]/, "Enter at least one uppercase letter")
            .matches(/[0-9]/, "Enter at least one number")
            .matches(/[@$!%*?&#]/, "Enter at least one special character")
            .required("Please enter your password"),
        // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/, 'Enter strong password.')
        userConfirmPwd: Yup.string()
            .oneOf([Yup.ref('userPwd'), null], 'Passwords must be same.')
            .trim()
            .min(8, "Password must be at least 8 characters")
            .matches(/[a-z]/, "Enter at least one lowercase letter")
            .matches(/[A-Z]/, "Enter at least one uppercase letter")
            .matches(/[0-9]/, "Enter at least one number")
            .matches(/[@$!%*?&#]/, "Enter at least one special character")
            .required("Please enter your password"),
    });

    const handleSubmit = async (values, { resetForm }) => {
        loader(true);
        const headers = {
            'Authorization': `Bearer ${params?.token}`,
        };

        try {
            const response = await axios.post(`${ApiKey.api}forgotpassword`, { new_password: values?.userPwd }, { headers: headers });
            if (response?.data?.status) {
                resetForm();
                toast.success(response?.data?.message);
                navigate('/login');
            } else {
                toast.error(response?.data?.message);

            }
            loader(false);
        } catch (error) {
            toast.error(error?.response?.data?.message);
            loader(false);
        }
    }
    useEffect(() => { document.title = 'Apex Bullion-ChangePassword' }, []);
    return (
        <div className="mx-auto h-[100vh]">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 h-full">
                <div className='bgImage h-full flex justify-center items-center '>
                    <img src="/Assets/loginpic.png" alt="" className='max-w-full p-5' />
                </div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={forgotPassFormSchema}
                    onSubmit={(values, { resetForm }) => {
                        handleSubmit(values, { resetForm });
                    }}
                >
                    {({ errors, touched }) => (
                        <div className='frontImage h-full flex justify-center items-center px-5 py-3'>
                            <Form autoComplete="off">
                                <div className='flex flex-col items-center '>
                                    <h3 className='font-semibold text-2xl text-t'>
                                        <span className='gradientText bg-clip-text'>Forgot your password?</span>
                                    </h3>
                                    <p className=' gradientText bg-clip-text text-sm font-normal mt-3 text-center'>
                                        Don't worry update change your password here.
                                    </p>

                                    <div className="relative w-full mt-5 text-white">
                                        <Field name="userPwd">
                                            {({ field }) => (
                                                <Input.Password
                                                    {...field}
                                                    className="inputBoxBorder rounded-md inputBoxBg w-full text-white py-2 px-5"
                                                    placeholder='Enter Password'
                                                />
                                            )}
                                        </Field>
                                        {/* <Field
                                            type={showPassword ? 'text' : 'password'}
                                            name="userPwd"
                                            className="inputBoxBorder text-white rounded-md inputBoxBg w-full   py-2 px-5"
                                            placeholder="Enter Password"
                                        />
                                        <img src={`/Assets/${showPassword ? 'eye' : 'View_hide'}.png`} alt=""
                                            onClick={() => setShowPassword(!showPassword)}
                                            className='absolute top-[40%] right-3 cursor-pointer'
                                            style={{ transform: "translatey(-50%)" }}
                                        /> */}
                                        <div className="errorMsg">
                                            <p className="m-0">{errors.userPwd && touched.userPwd ? errors.userPwd : null}</p>
                                        </div>

                                    </div>

                                    <div className="relative w-full mt-3">
                                        <Field name="userConfirmPwd">
                                            {({ field }) => (
                                                <Input.Password
                                                    {...field}
                                                    className="inputBoxBorder rounded-md inputBoxBg w-full text-white py-2 px-5"
                                                    placeholder='Enter Confirm Password'
                                                />
                                            )}
                                        </Field>                                        
                                        {/* <Field
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            name="userConfirmPwd"
                                            className="inputBoxBorder text-white rounded-md inputBoxBg w-full   py-2 px-5"
                                            placeholder="Enter Confirm Password"
                                        />
                                        <img src={`/Assets/${showConfirmPassword ? 'eye' : 'View_hide'}.png`} alt=""
                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                            className='absolute top-[40%] right-3 cursor-pointer'
                                            style={{ transform: "translatey(-50%)" }}
                                        /> */}
                                        <div className="errorMsg">
                                            <p className="m-0">{errors.userConfirmPwd && touched.userConfirmPwd ? errors.userConfirmPwd : null}</p>
                                        </div>
                                    </div>

                                    <div className='w-full mt-3'>
                                        <button type='submit' className='btnGradient rounded-md w-full mt-1   font-normal text-sm py-2 text-white'>Submit</button>
                                        <button className='btnGradient rounded-md w-full mt-2 font-normal text-sm py-2 text-white' onClick={() => navigate('/login')} >Cancel
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    )}
                </Formik>
            </div>
        </div>
    )
}
