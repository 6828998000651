
import moment from "moment";
import { Link } from "react-router-dom";

const getRowNumber = (currentPage, index) => {
    return (currentPage - 1) * 10 + index + 1;
};

const clientManager_column = (currentPage) => [
    {
        title: 'No.',
        dataIndex: 'no',
        render: (text, record, index) => getRowNumber(currentPage, index),
    },
    {
        title: 'Name',
        dataIndex: 'first_name',
        key: 'name',
        sorter: (a, b) => a.first_name?.localeCompare(b.first_name),
        render: (text, record) => (<Link to={`/clientdetail/${record.id}`}  className="darkYellowText hover:text-[#f6da6d]">{record?.first_name} {record?.last_name}</Link>)
    },
    {
        title: 'Email ID',
        dataIndex: 'email',
        key: 'email',
        sorter: (a, b) => a.email?.localeCompare(b.email),
    },
    {
        title: 'Mobile Number',
        dataIndex: 'mobile_no',
        key: 'mobile_no',
        sorter: (a, b) => a.mobile_no?.localeCompare(b.mobile_no),
    },  
    {
        title: 'Country',
        dataIndex: 'country',
        key: 'country',
        sorter: (a, b) => a.country?.localeCompare(b.country),
    },
    {
        title: 'Joinig Date',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: (a, b) => moment(a.created_at) - moment(b.created_at),
        render: (text) => <div>{moment(text).format('lll')}</div>
    }
];

export default clientManager_column;