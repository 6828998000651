import React, { useEffect, useState } from 'react';
import Navbar from '../Components/Navbar';
import loader from '../Services/Loader/Loader';
import ApiClient from '../Services/ApiServices/ApiClinet';
import { toast } from 'react-toastify';
import { Pagination, Radio, Table } from 'antd';
import kycList_columns from '../Columns/KycList/kycList';
import ModalComponent from '../Components/Modal';

export default function KycList() {

    const [statusFilter, setStatusFilter] = useState('pending');
    const [kycList, setKycList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [isAcceptOpen, setIsAcceptOpen] = useState(false);
    const [isRejectOpen, setIsRejectOpen] = useState(false);
    const [clientId, setClientId] = useState(null);
    const [kycId, setKycId] = useState(null);

    const getKycList = (page) => {
        loader(true);
        ApiClient.post('kyclist', { kyc_status: statusFilter, page })
            .then((response) => {
                if (response?.status) {
                    setKycList(response?.data);
                    setTotalPages(response?.total_record);
                }
                else {
                    toast.error(response?.message);
                }
                loader(false);
            })
            .catch(error => {
                toast.error(error.message);
                loader(false);
            });
    };

    const updateStatus = (params) => {
        ApiClient.post('changekycstatus', params)
            .then((response) => {
                if (response?.status) {
                    getKycList(currentPage);
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
                setIsAcceptOpen(false);
                setIsRejectOpen(false);
            })
            .catch(error => {
                toast.error(error.message);
                loader(false);
            });
    };

    const handleAcceptData = (client_id, kyc_id) => {
        setClientId(client_id);
        setKycId(kyc_id);
        setIsAcceptOpen(true);
    }
    const handleAccept = () => {
        const params = {
            client_id: clientId,
            id: kycId,
            status: 'completed'
        };
        updateStatus(params);
    };

    const handleRejectData = (client_id, kyc_id) => {
        setClientId(client_id);
        setKycId(kyc_id);
        setIsRejectOpen(true);
    }
    const handleReject = () => {
        const params = {
            client_id: clientId,
            id: kycId,
            status: 'reject'
        };
        updateStatus(params);
    };


    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    useEffect(() => {
        getKycList(currentPage)
        document.title = 'Apex Bullion-KYC List'
    }, [currentPage, statusFilter]);

    useEffect(() => {
        setCurrentPage(1)
    }, [statusFilter])


    return (
        <>
            <Navbar currentPage='KYC List' />
            <div className='flex sm:flex-row flex-col gap-2 justify-end mt-3'>
                <Radio.Group value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)} buttonStyle="solid" className='flex'>
                    <Radio.Button className='text-nowrap text-center' value="pending">Pending</Radio.Button>
                    <Radio.Button className='text-nowrap text-center' value="completed">Completed</Radio.Button>
                    <Radio.Button className='text-nowrap text-center' value="reject">Reject</Radio.Button>
                </Radio.Group>
            </div>
            <div className="relative overflow-x-auto shadow-md darkBg rounded-lg border-2 borderStyle mt-4">
                <Table dataSource={kycList} columns={kycList_columns(currentPage, handleAcceptData, handleRejectData, statusFilter)} pagination={false} />
            </div>
            <div className='text-right mt-5'>
                <Pagination current={currentPage} total={totalPages} onChange={handlePageChange} />
            </div>
            <ModalComponent isOpen={isAcceptOpen} setIsOpen={setIsAcceptOpen} title='' width='25%'>
                <div className="flex flex-col justify-center items-center">
                    <img src="/Assets/Accept-icon.svg" alt="" className='h-30 p-4' />
                    <h2 className='logoutText mt-4'>Are You Sure, You Want To Accept ?</h2>
                    <div className="flex mt-5 w-full">
                        <button className=" px-5 py-2 bg-green-500 hover:bg-green-600 rounded-md font-semibold w-full mx-3" onClick={() => handleAccept()}>Yes</button>
                        <button className='px-5 py-2 bg-red-500 hover:bg-red-600 rounded-md font-semibold w-full mx-3' onClick={() => setIsAcceptOpen(false)}>No</button>
                    </div>
                </div>
            </ModalComponent>
            <ModalComponent isOpen={isRejectOpen} setIsOpen={setIsRejectOpen} title='' width='25%'>
                <div className="flex flex-col justify-center items-center">
                    <img src="/Assets/Reject-icon.svg" alt="" className='h-30 p-4' />
                    <h2 className='logoutText mt-4'>Are You Sure, You Want To Reject ?</h2>
                    <div className="flex mt-5 w-full">
                        <button className=" px-5 py-2 bg-green-500 hover:bg-green-600 rounded-md font-semibold w-full  mx-3" onClick={() => handleReject()}>Yes</button>
                        <button className='px-5 py-2 bg-red-500 hover:bg-red-600 rounded-md font-semibold w-full mx-3 ' onClick={() => setIsRejectOpen(false)}>No</button>
                    </div>
                </div>
            </ModalComponent>
        </>
    )
}
