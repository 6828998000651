
import React, { useContext, useEffect, useState } from 'react';
import { SidebarContext } from '../Context';
import useWebSocket from 'react-use-websocket';
import axios from 'axios';


export default function Navbar({ currentPage }) {

    const { showsideBar, setshowSideBar } = useContext(SidebarContext)
    const [price, setPrice] = useState({ buy: 0.00, sell: 0.00 })
    const showSideBarToggle = () => {
        setshowSideBar(!showsideBar);
    }
    const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket('wss://liverate.fxopulence.com/ticks', {
        retryOnError: true,
        onClose: () => {
            console.log("WebSocket closed, attempting to reconnect...");
        },
        onError: () => {
            console.log("WebSocket error, attempting to reconnect...");
        }
    });

    useEffect(() => {
        const day = new Date().getDay();
        if (day == 6 || day == 7) {
            axios.post('https://liverate.fxopulence.com/api/v1/symbols/rate', { symbols: ["XAUUSD"] }).then(({ data }) => {
                const data1 = data?.ticks[0]
                setPrice({ buy: data1?.ask, sell: data1?.bid })
            })
        } else {
            if (readyState === WebSocket.OPEN) {
                sendJsonMessage({
                    symbols: [
                        "XAUUSD", // Gold
                    ]
                });
            }
        }
    }, [sendJsonMessage, readyState]);

    useEffect(() => {
        if (lastJsonMessage) {
            setPrice({ buy: lastJsonMessage.ask, sell: lastJsonMessage.bid });
        }
    }, [lastJsonMessage]);

    return (
        <>
            <div className='darkBg border borderStyle rounded-md px-3 py-2'>
                <div className='flex sm:flex-row flex-col items-center justify-between'>
                    <div className='flex items-center gap-3'>
                        <div onClick={showSideBarToggle} className='cursor-pointer text-white h-6 w-6'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512 " className='fill-white  h-[25px] '><path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" /></svg>
                        </div>
                        <p className='lightYellowText font-medium text-xl'>
                            {currentPage}
                        </p>
                    </div>
                    <div className='flex items-center sm:text-sm text-[12px] gap-2 py-2 '>
                        <div className='bg-transparent font-normal flex flex-row gap-2 px-3 '>
                            <div className='rounded-[50%] bg-[#262626] flex justify-center items-center w-8 h-8 my-auto'>
                                <img src="/Assets/up_price.svg" alt="" />
                            </div>
                            <div>
                                <p>Buy Rate</p>
                                <p className='text-[#3AD770] w-16'>${price.buy || "00.00"}</p>
                            </div>
                        </div>
                        <div className='border-l-2 border-[#262626] h-10'></div>
                        <div className='bg-transparent font-normal flex flex-row gap-2 px-3'>
                            <div className='rounded-[50%] bg-[#262626] flex justify-center items-center w-8 h-8 my-auto'>
                                <img src="/Assets/down_price.svg" alt="" />
                            </div>
                            <div>
                                <p>Sell Rate</p>
                                <p className='text-[#E46253] w-16'>${price.sell || "00.00"}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
