import { Image, Tooltip } from "antd";
import moment from "moment/moment";

const getRowNumber = (currentPage, index) => {
    return (currentPage - 1) * 10 + index + 1;
};    

const notification_columns = (currentPage) =>[
    {
        title: 'No.',
        dataIndex: 'no',
        render: (text, record, index) => getRowNumber(currentPage, index),
    },
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        sorter: (a, b) => a.title?.localeCompare(b.title),
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        sorter: (a, b) => a.description?.localeCompare(b.description),
        render: (text) => (
            <Tooltip title={text} overlayStyle={{ backgroundColor: '#f6da6d' }}>
                {text.length > 40 ? `${text.substring(0, 40)}....` : text}
            </Tooltip>
        ),
    },
    {
        title: 'Image',
        dataIndex: 'image',
        key: 'image',
        render: (text, record) => (
            <Image src={text} alt="" style={{ borderRadius: '50%', width: '30px', height: '30px' }} />
        ),
    },
    {
        title: 'UpDated At',
        dataIndex: 'updated_at',
        key: 'updated_at',
        sorter: (a, b) => moment(a.updated_at) - moment(b.updated_at),
        render: (text) => <div>{moment(text).format('lll')}</div>
    },

]
export default notification_columns;
