
import React, { useEffect, useRef, useState } from 'react';
import Navbar from '../Components/Navbar';
import { Image, Pagination, Table, Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import loader from '../Services/Loader/Loader';
import ApiClient from '../Services/ApiServices/ApiClinet';
import { toast } from 'react-toastify';
import news_columns from '../Columns/News/news';
import ModalComponent from '../Components/Modal';
import { Field, Formik, Form } from 'formik';
import * as Yup from "yup";
import Dragger from 'antd/es/upload/Dragger';

export default function News() {

  const [newsData, setNewsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [removeOpen, setRemoveOpen] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [newsId, setNewsId] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [changeStatus, setChangeStatus] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentNews, setCurrentNews] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [draggerKey, setDraggerKey] = useState(Date.now());
  const [imagePreview, setImagePreview] = useState(null);

  const formikRef = useRef();

  const initialValues = {
    title: '',
    description: '',
  };

  const newsSchema = Yup.object().shape({
    title: Yup.string()
      .trim()
      .required("title required."),
    description: Yup.string()
      .trim()
      .required("description required."),
  });

  const getNews = (page) => {
    loader(true);
    ApiClient.post('newslist', { page })
      .then((response) => {
        if (response.status) {
          setNewsData(response.data);
          setTotalPages(response?.total_record);
        } else {
          toast.error(response.message);
        }
        loader(false);
      })
      .catch(error => {
        toast.error(error.message);
        loader(false);
      })
  }

  const handleSubmit = (values, { resetForm }) => {
    const apiEndpoint = isEditMode ? 'newsedit' : 'newsadd';
    const payload = isEditMode ? { ...values, news_id: newsId, image: imageFile } : { ...values, image: imageFile };

    ApiClient.postFormData(apiEndpoint, payload)
      .then((response) => {
        if (response.status) {
          resetForm();
          setIsAddOpen(false);
          toast.success(response.message);
          setFormSubmitted(true);
        } else {
          toast.error(response.message);
        }
      })
      .catch(error => {
        toast.error(error.message);
        loader(false);
      });
  }

  const handleRemoveId = (news_id) => {
    setNewsId(news_id);
    setRemoveOpen(true);
  }

  const handleRemove = () => {
    ApiClient.post('newsdelete', { news_id: newsId })
      .then((response) => {
        if (response.status) {
          getNews(currentPage);
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
        setRemoveOpen(false);
      })
      .catch(error => {
        toast.error(error.message);
        loader(false);
      })
  }

  const handleEditNews = (news_id) => {
    const newsItem = newsData.find(news => news.id === news_id);
    setCurrentNews(newsItem);
    setNewsId(news_id);
    setIsEditMode(true);
    setIsAddOpen(true);
  }

  const handleStatus = (news_id, status) => {
    loader(true);
    ApiClient.post('newsstatuschange', { news_id, status })
      .then((response) => {
        if (response.status) {
          setChangeStatus(true);
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
        loader(false);
      })
      .catch(error => {
        toast.error(error.message);
        loader(false);
      })
  }

  const handleUpload = (info) => {
    const fileList = info.fileList;
    if (fileList && fileList.length > 0) {
      setImageFile(fileList[0].originFileObj);
      setImagePreview(URL.createObjectURL(fileList[0].originFileObj));
    }
  };

  useEffect(() => {
    getNews(currentPage);
    document.title = 'Apex Bullion-News';
  }, [currentPage]);

  useEffect(() => {
    if (!isAddOpen && formikRef.current) {
      setIsEditMode(false)
      setImageFile(null);
      setImagePreview(null);
    }
    if (!isAddOpen) {
      setDraggerKey(Date.now());
    }
  }, [isAddOpen]);


  useEffect(() => {
    if (formSubmitted) {
      getNews(currentPage);
      setFormSubmitted(false);
    }
  }, [formSubmitted]);

  useEffect(() => {
    if (changeStatus) {
      getNews(currentPage);
      setChangeStatus(false);
    }
  }, [changeStatus]);

  return (
    <>
      <Navbar currentPage='News' />

      <div className='mt-5 flex justify-end'>
        <button type="button" onClick={() => setIsAddOpen(true)} className="btnGradient rounded-md flex items-center gap-2 px-2 py-1">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill='white' width="20"
            height="20"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" /></svg>
          Add News</button>
      </div>

      <div className="overflow-x-auto shadow-md darkBg rounded-lg border-2 borderStyle mt-3">
        <Table dataSource={newsData} columns={news_columns(handleStatus, handleRemoveId, handleEditNews, currentPage)} pagination={false} />
      </div>

      <div className='text-right mt-5'>
        <Pagination current={currentPage} total={totalPages} onChange={page => setCurrentPage(page)} />
      </div>

      <ModalComponent isOpen={isAddOpen} setIsOpen={setIsAddOpen} title={isEditMode ? 'Edit News' : 'Add News'} width='50%'>
        <Formik
          innerRef={formikRef}
          initialValues={isEditMode && currentNews ? currentNews : initialValues}
          validationSchema={newsSchema}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values, { resetForm });
          }}
          enableReinitialize
        >
          {({ errors, touched }) => (
            <Form className="darkBg border-2 borderStyle rounded-md p-7 grid md:grid-cols-1 items-center gap-3" autoComplete='off'>
              <div>
                <p className="darkYellowText">Title</p>
                <Field type="text" name="title" placeholder='Title' className="w-full inputBoxBorder inputBoxBg rounded-md py-2 px-3 mb-1" />
                <div className="errorMsg">
                  <p className="m-0">{errors.title && touched.title ? errors.title : null}</p>
                </div>
              </div>
              <div>
                <p className="darkYellowText">Description</p>
                <Field as="textarea" name="description" placeholder='Description' className="w-full inputBoxBorder inputBoxBg rounded-md py-2 px-3 " />
                <div className="errorMsg">
                  <p className="m-0">{errors.description && touched.description ? errors.description : null}</p>
                </div>
              </div>
              <div className='mt-2'>
                <Dragger
                  onChange={handleUpload}
                  key={draggerKey} maxCount={1}
                  beforeUpload={() => false}
                  onRemove={() => {
                    setImageFile(null);
                    setImagePreview(null);
                  }}
                >
                  <p>
                    <InboxOutlined />
                    <p >Click or drag file to this area to upload</p>
                  </p>
                </Dragger>
                {imagePreview && (
                  <div className="mt-2 flex justify-end">
                    <Image src={imagePreview} alt="Preview" height='50px' width='50px' className='object-contain' />
                  </div>
                )}
              </div>
              <div>
                <button type="submit" className='btnGradient rounded-md px-5 py-1 mt-3 hover:scale-105 duration-300'>{isEditMode ? 'Update' : 'Submit'}</button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalComponent>

      <ModalComponent isOpen={removeOpen} setIsOpen={setRemoveOpen} title='' width='25%'>
        <div className="flex flex-col justify-center items-center">
          <img src="/Assets/Reject-icon.svg" alt="" className='h-30 p-4' />
          <h2 className='logoutText mt-4'>Are You Sure, You Want To Remove ?</h2>
          <div className="flex mt-5 w-full">
            <button className=" px-5 py-2 bg-green-500 hover:bg-green-600 rounded-md font-semibold w-full  mx-3"
              onClick={() => handleRemove()}>Yes</button>
            <button className='px-5 py-2 bg-red-500 hover:bg-red-600 rounded-md font-semibold w-full mx-3 '
              onClick={() => setRemoveOpen(false)}>No</button>
          </div>
        </div>
      </ModalComponent>
    </>
  )
}
